import {
  addDays as fnsAddDays,
  differenceInMinutes,
  differenceInYears as fnsDifferenceInYears,
  eachDayOfInterval as fnsEachDayOfInterval,
  isBefore,
  isToday as fnsIsToday,
  isSameDay as fnsIsSameDay,
  isValid,
  format,
  parse,
  parseISO as fnsParseISO,
  startOfMinute,
  startOfWeek as fnsStartOfWeek,
  startOfMonth,
  subDays as fnsSubDays,
} from 'date-fns';
import { toZonedTime, fromZonedTime, getTimezoneOffset } from 'date-fns-tz';

export const BROWSER_DATE_FORMAT = 'yyyy-MM-dd';
export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const TIME_FORMAT = 'HH:mm';
export const TIMESTAMP_FORMAT = `${BROWSER_DATE_FORMAT}'T'${TIME_FORMAT}:ss.SSSXXX`;
export const DEFAULT_FIELD_FORMAT = `${US_DATE_FORMAT} ${TIME_FORMAT}`;

export const parseISO = (dateString) => dateString && fnsParseISO(dateString);

export const formatDate = (date, formatPattern = DEFAULT_FIELD_FORMAT) =>
  date ? format(date, formatPattern) : null;

export const formatToBrowserDate = (date) =>
  formatDate(date, BROWSER_DATE_FORMAT);

export const formatDateToStr = (date, pattern = US_DATE_FORMAT) =>
  formatDate(date, pattern) || '--';

export const formatToUserTimezone = (date, formatPattern) =>
  date &&
  `${formatDate(date, formatPattern)} (${Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split('/')
    .pop()})`;

export const formatToSpecificTimezone = (
  date,
  formatPattern,
  timezoneOffset,
) => {
  const zonedTime = toZonedTime(date, timezoneOffset);
  return formatDate(zonedTime, formatPattern);
};

export const formatIsoDate = (dateInput, formatPattern = US_DATE_FORMAT) => {
  if (!dateInput) return null;
  return formatToSpecificTimezone(dateInput, formatPattern, 'UTC');
};

export const isValidDate = (date) => date == null || isValid(date);

export const dateDiffFormatted = (start, end) => {
  const startDate = startOfMinute(start);
  const endDate = startOfMinute(end);
  if (isBefore(endDate, startDate)) return null;
  const diffMinutes = differenceInMinutes(endDate, startDate);
  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return null;
  }
  return `${hours}h ${minutes}m`;
};

export const startOfMonthStr = () =>
  formatDate(startOfMonth(new Date()), TIMESTAMP_FORMAT);

export const parseDateStr = (str, pattern = BROWSER_DATE_FORMAT) =>
  str ? parse(str, pattern, new Date()) : null;

export const getTimezoneTimeString = (timezoneStr) => {
  const offsetHours = getTimezoneOffset(timezoneStr) / 3600000;
  const sign = offsetHours < 0 ? '-' : '+';
  const absOffset = Math.abs(offsetHours);
  return `${sign}${absOffset > 9 ? absOffset : `0${absOffset}`}:00`;
};

export const addDays = fnsAddDays;
export const differenceInYears = fnsDifferenceInYears;
export const eachDayOfInterval = fnsEachDayOfInterval;
export const isToday = fnsIsToday;
export const isSameDay = fnsIsSameDay;
export const startOfWeek = fnsStartOfWeek;
export const subDays = fnsSubDays;
export const utcToZonedTime = toZonedTime;
export const zonedTimeToUtc = fromZonedTime;
