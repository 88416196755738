import React from 'react';
import PropTypes from 'prop-types';

import { RequestDetails } from 'features/consults/components/ConsultRequestDetails/ConsultRequestDetails.style';
import { NeuroConsultLabels } from 'utils/enums';

const NeuroConsultDetails = ({
  neuroConsultType,
  neuroCameraName,
  cameraName,
  directBeamInRequested,
  stat,
}) => (
  <>
    <RequestDetails>{`Neuro Consult Type: ${NeuroConsultLabels[neuroConsultType]}`}</RequestDetails>
    <RequestDetails>{`Camera Name: ${neuroCameraName || cameraName || '--'}`}</RequestDetails>
    {directBeamInRequested && (
      <RequestDetails>Direct Beam-In Requested</RequestDetails>
    )}
    {stat && <RequestDetails>STAT Gen Neuro callback</RequestDetails>}
  </>
);

NeuroConsultDetails.propTypes = {
  neuroConsultType: PropTypes.string,
  cameraName: PropTypes.string,
  neuroCameraName: PropTypes.string,
  directBeamInRequested: PropTypes.bool,
  stat: PropTypes.string,
};

export default NeuroConsultDetails;
