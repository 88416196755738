import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import api from 'api';
import { useMountEffect, useRequest } from 'shared/hooks';
import { ServiceTypeLabels } from 'utils/enums';
import { formatIsoDate } from 'utils/dates';

import Button from 'shared/components/buttons/Button/Button';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import ConsultIntegrationDialog from 'features/consults/components/ConsultIntegration/ConsultIntegrationDialog';
import ConsultIntegrationUnmatch from 'features/consults/components/ConsultIntegration/ConsultIntegrationUnmatch';

const consultDetails = [
  {
    label: 'Consult Type',
    renderValue: ({ type }) => ServiceTypeLabels[type],
  },
  {
    label: 'First Name',
    renderValue: ({ demographics: { firstName } }) => firstName,
  },
  {
    label: 'Last Name',
    renderValue: ({ demographics: { lastName } }) => lastName,
  },
  {
    label: 'DOB',
    renderValue: ({ demographics: { dateOfBirth } }) =>
      formatIsoDate(dateOfBirth),
  },
];

const OrderUnmatch = ({ consultId, onUnmatch }) => {
  const [showConfirm, setShowConfirm] = React.useState(false);

  const { data: consultData, doRequest: getConsult } = useRequest(
    api.getConsultById,
  );

  useMountEffect(() => {
    getConsult(consultId);
  });

  const renderDetails = React.useMemo(() => {
    if (!consultData?.consult) return null;
    return consultDetails.map(({ label, renderValue }) => (
      <Stack key={label}>
        <Typography variant="subtitle2">{label}:</Typography>
        <Typography variant="body2">
          {renderValue(consultData.consult)}
        </Typography>
      </Stack>
    ));
  }, [consultData?.consult]);

  if (!consultData) return <LoadingSpinner />;

  return (
    <>
      {showConfirm && (
        <ConsultIntegrationDialog open onClose={() => setShowConfirm(false)}>
          <ConsultIntegrationUnmatch
            onUnlink={() => onUnmatch(consultId)}
            onCancel={() => setShowConfirm(false)}
          />
        </ConsultIntegrationDialog>
      )}
      {!!consultData?.consult && (
        <Stack spacing={2} p={2} bgcolor="grey.200" mb={2}>
          <Typography fontSize={16} variant="subtitle2">
            Linked consult details:
          </Typography>
          <Stack direction="row" spacing={5}>
            {renderDetails}
          </Stack>
        </Stack>
      )}
      <Stack direction="row" spacing={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowConfirm(true)}
        >
          Unmatch
        </Button>
        <Button
          href={`/admin/consults/${consultId}`}
          target="_blank"
          variant="contained"
          color="secondary"
        >
          View matched consult
        </Button>
      </Stack>
    </>
  );
};

OrderUnmatch.propTypes = {
  consultId: PropTypes.string.isRequired,
  onUnmatch: PropTypes.func.isRequired,
};

export default OrderUnmatch;
