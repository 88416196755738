import sortFields from 'api/sortFields';
import { useTableState, useFilterState } from 'shared/hooks';
import { ConsultRequestStatusEnum } from 'utils/enums';

const useConsultRequestListState = () => {
  const { page, handlePageChange, sortBy, sortOrder, setQueryParams } =
    useTableState(sortFields.consultRequests.createdAt, 'desc');

    const initialFilters = {
      facilityId: null,
      query: null,
      state: ConsultRequestStatusEnum.OPEN,
      consultType: null,
    };

  const { filters, handleFilter, handleResetFilters } = useFilterState(initialFilters);

  const resetFilters = (additionalFilters = {}) => {
    const mergedFilters = { ...initialFilters, ...additionalFilters };
    handleResetFilters(mergedFilters);
  };


  return {
    page,
    filters,
    handlePageChange,
    sortBy,
    sortOrder,
    handleFilterChange: handleFilter,
    setQueryParams,
    resetFilters,
  };
};

export default useConsultRequestListState;
