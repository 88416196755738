import * as yup from 'yup';

import { requiredString } from 'utils/constants';
import { maxLimit, PHONE_NUMBER_REGEX, ZIP_CODE_REGEX } from 'utils/validators';

import { facilityFields } from './utils';

export default yup.object().shape({
  [facilityFields.active]: yup.bool(),
  [facilityFields.name]: yup
    .string()
    .trim()
    .required(requiredString)
    .max(50, maxLimit),
  [facilityFields.street]: yup.string().nullable().trim().max(180, maxLimit),
  [facilityFields.city]: yup.string().nullable().trim().max(100, maxLimit),
  [facilityFields.zipCode]: yup
    .string()
    .nullable()
    .trim()
    .max(5, maxLimit)
    .matches(ZIP_CODE_REGEX, 'Invalid zip code'),
  [facilityFields.phone]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  [facilityFields.itPhone]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  [facilityFields.callCenterPhone]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  [facilityFields.fax]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid fax number'),
  [facilityFields.emrName]: yup.string().nullable().trim().max(180, maxLimit),
  [facilityFields.emrLink]: yup
    .string()
    .nullable()
    .trim()
    .max(180, maxLimit)
    .url('Invalid url'),
  [facilityFields.radiology]: yup.string().nullable().trim().max(180, maxLimit),
  [facilityFields.radiologyLink]: yup
    .string()
    .nullable()
    .trim()
    .max(180, maxLimit)
    .url('Invalid url'),
  [facilityFields.sharedSignout]: yup.string().nullable().trim().max(180, maxLimit),
  [facilityFields.sharedSignoutLink]: yup
    .string()
    .nullable()
    .trim()
    .max(180, maxLimit)
    .url('Invalid url'),
  [facilityFields.camera]: yup.string().nullable().trim().max(180, maxLimit),
  [facilityFields.cameraLink]: yup
    .string()
    .nullable()
    .trim()
    .max(180, maxLimit)
    .url('Invalid url'),
});
