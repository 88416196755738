import {
  neuroConsultOptions,
  teleNeuroConsultOptions,
  eegConsultOptions,
} from 'features/consults/utils/constants';
import { ConsultFieldTypeEnum, EegConsultTypes } from 'utils/enums';

export const phoneAgreementValue = 'PhoneAgreement';

export const PatientLocations = {
  EmergencyRoom: 'EmergencyRoom',
  Inpatient: 'Inpatient',
  Outpatient: 'Outpatient',
};

export const patientLocationLabels = {
  [PatientLocations.EmergencyRoom]: 'Emergency Room',
  [PatientLocations.Inpatient]: 'Inpatient',
  [PatientLocations.Outpatient]: 'Outpatient',
};

const patientLocationOptions = Object.entries(patientLocationLabels).map(
  ([value, label]) => ({ value, label }),
);

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'NonBinary', label: 'Non-binary' },
];

export const observationOptions = [
  { value: 'HookUp', label: 'hook-up' },
  { value: 'ObservationDay', label: 'subsequent day' },
  { value: 'DisconnectedEndClip', label: 'disconnected/end clip' },
];

export const videoOptions = [
  { value: 'Video', label: 'video' },
  { value: 'WithoutVideo', label: 'without video' },
];

export const eegStatOptions = {
  STATReadRequested: 'STAT read requested',
};

const demographicsFields = {
  consultType: {
    name: 'consultType',
    title: 'Consult Type',
    type: ConsultFieldTypeEnum.TOGGLE,
    required: true,
    options: neuroConsultOptions,
  },
  observationType: {
    name: 'observationType',
    associatedField: 'consultType',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: observationOptions,
  },
  videoType: {
    name: 'videoType',
    associatedField: 'consultType',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: videoOptions,
  },
  neuroPatientLocation: {
    name: 'neuroPatientLocation',
    title: 'Patient Location',
    type: ConsultFieldTypeEnum.TOGGLE,
    required: true,
    options: patientLocationOptions.filter(
      ({ value }) => value !== patientLocationLabels.Outpatient,
    ),
  },
  eegPatientLocation: {
    name: 'eegPatientLocation',
    title: 'Patient Location',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: patientLocationOptions,
  },
  eegStatOptions: {
    name: 'eegStatOptions',
    associatedField: 'eegPatientLocation',
    type: ConsultFieldTypeEnum.CHIP,
    options: Object.entries(eegStatOptions).map(([value, label]) => ({
      value,
      label,
    })),
  },
  firstName: {
    name: 'firstName',
    label: 'First Name',
    type: ConsultFieldTypeEnum.INPUT,
    required: true,
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    type: ConsultFieldTypeEnum.INPUT,
    required: true,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    type: ConsultFieldTypeEnum.DATE,
  },
  age: {
    name: 'age',
    label: 'Age',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
    inputType: 'number',
  },
  gestationalAge: {
    name: 'gestationalAge',
    label: 'Gestational Age',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
    inputType: 'number',
    required: false,
    summaryLabel: 'Gestational Age',
    summaryContent: ({ fieldValue }) => `${fieldValue} weeks`,
  },
  gender: {
    name: 'gender',
    title: 'Gender',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: genderOptions,
  },
  dateOfVisit: {
    name: 'dateOfVisit',
    label: 'Date of Visit',
    summaryLabel: 'Date of Clinic Visit',
    type: ConsultFieldTypeEnum.DATE,
    inlineLabel: true,
  },
  prehospitalBeamIn: {
    name: 'prehospitalBeamIn',
    label: 'Prehospital/Beam In',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'PrehospitalAlert', label: 'prehospital alert' },
      { value: 'DirectBeamIn', label: 'direct beam-in' },
    ],
    inlineLabel: true,
    excludeFromSummary: true,
  },
  facility: {
    name: 'facilityId',
    label: 'Facility',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    required: true,
  },
  referringProvider: {
    name: 'referringProvider',
    label: 'Referring Provider',
    summaryLabel: 'Referring Provider/PCP',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  callBackPhone: {
    name: 'callBackPhone',
    label: 'Call Back Phone',
    type: ConsultFieldTypeEnum.PHONE,
    excludeFromSummary: true,
  },
  callBackPhoneExtension: {
    name: 'callBackPhoneExtension',
    label: 'Extension',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  referringTechnician: {
    name: 'referringTechnician',
    label: 'Referring Technician',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  referringRN: {
    name: 'referringRN',
    label: 'Referring RN',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  rnCallbackNumber: {
    name: 'rnCallbackNumber',
    label: 'RN Callback Number',
    type: ConsultFieldTypeEnum.PHONE,
    excludeFromSummary: true,
  },
  rnCallbackNumberExtension: {
    name: 'rnCallbackNumberExtension',
    label: 'Extension',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  techCallbackNumber: {
    name: 'techCallbackNumber',
    label: 'Tech Call Back Phone',
    type: ConsultFieldTypeEnum.PHONE,
    excludeFromSummary: true,
  },
  techCallbackNumberExtension: {
    name: 'techCallbackNumberExtension',
    label: 'Extension',
    type: ConsultFieldTypeEnum.PHONE,
    excludeFromSummary: true,
  },
  cameraName: {
    name: 'cameraName',
    label: 'Camera Name',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  notes: {
    name: 'notes',
    label: 'Notes',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
    excludeFromSummary: true,
  },
  initialPageAt: {
    name: 'initialPageAt',
    label: 'Time of Initial Page',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  returnCallAt: {
    name: 'returnCallAt',
    label: 'Time of Return Call',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  stat: {
    name: 'stat',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'StatGenNeuroCallback',
        label: 'STAT Gen Neuro callback',
      },
    ],
    excludeFromSummary: true,
    disabled: true,
  },
  phoneOnly: {
    name: 'phoneOnly',
    label: 'Phone Only',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'PhoneOnly',
        label: 'phone only',
      },
    ],
    excludeFromSummary: true,
  },
  phoneOnlyFreetext: {
    name: 'phoneOnlyFreetext',
    label: 'Phone Only Freetext',
    summaryLabel: 'Phone Only Consult',
    type: ConsultFieldTypeEnum.DICTATION,
    multiline: true,
    rows: 5,
  },
  phoneAgreement: {
    name: 'phoneAgreement',
    label: 'Phone Agreement',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: phoneAgreementValue,
        label: 'phone agreement',
        summaryLabel:
          'phone consult deemed mutually sufficient for patient care',
      },
    ],
  },
};

export const clinicDemographicsFields = {
  ...demographicsFields,
  referringProvider: {
    ...demographicsFields.referringProvider,
    excludeFromSummary: false,
  },
};

export const eegDemographicsFields = {
  ...demographicsFields,
  consultType: {
    ...demographicsFields.consultType,
    title: 'Type of EEG Read',
    required: true,
    options: eegConsultOptions,
  },
  eegPedsConsultType: {
    ...demographicsFields.consultType,
    name: 'eegPediatricConsultType',
    title: 'Type of EEG Read',
    required: true,
    options: eegConsultOptions.filter(
      ({ value }) => !(value === EegConsultTypes.ZETO),
    ),
  },
};

export const teleNeuroDemographicsFields = {
  ...demographicsFields,
  consultType: {
    ...demographicsFields.consultType,
    options: teleNeuroConsultOptions,
  },
  neuroPatientLocation: {
    ...demographicsFields.neuroPatientLocation,
    required: false,
  },
  dateOfBirth: {
    ...demographicsFields.dateOfBirth,
    required: true,
  },
  dateOfAdmission: {
    name: 'dateOfAdmission',
    label: 'Date of Admission',
    type: ConsultFieldTypeEnum.DATE,
    hasSetNowButton: true,
    required: true,
  },
  dateOfInitialConsultation: {
    name: 'dateOfInitialConsultation',
    label: 'Date of Initial Consultation',
    type: ConsultFieldTypeEnum.DATE,
    hasSetNowButton: true,
    required: true,
  },
  dateOfService: {
    name: 'dateOfService',
    label: 'Date of Service',
    type: ConsultFieldTypeEnum.DATE,
    hasSetNowButton: true,
    required: true,
  },
  initialPageAt: {
    ...demographicsFields.initialPageAt,
    excludeFromSummary: true,
  },
  returnCallAt: {
    ...demographicsFields.returnCallAt,
    excludeFromSummary: true,
  },
};

export default demographicsFields;
