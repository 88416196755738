import { LAST24HOURS } from 'features/consults/utils/constants';
import { formatDate, subDays, TIMESTAMP_FORMAT } from 'utils/dates';

// eslint-disable-next-line import/prefer-default-export
export const mapConsultFilters = ({
  fromCreatedAt,
  query,
  state,
  networkId,
  healthcareSystemId,
  facilityId,
  ...restFilters
}) => ({
  ...restFilters,
  query: query || null,
  state: state || null,
  fromCreatedAt:
    fromCreatedAt === LAST24HOURS
      ? formatDate(subDays(new Date(), 1), TIMESTAMP_FORMAT)
      : fromCreatedAt,
  networkId,
  healthcareSystemId,
  facilityId,
});
