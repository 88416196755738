import styled from '@emotion/styled';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import { Box, FlexBox } from 'styles/layout';

export const styleThemes = {
  lg: {
    contentWrapper: {
      margin: '20px',
      padding: '8px 30px',
    },
    actionsOuterWrapper: {
      direction: 'row',
      horizontalAlign: 'space-between',
    },
    actionsInnerWrapper: {
      order: 2,
      marginBottom: 0,
    },
    consultButtonWrapper: {
      margin: '0 32px 0 0',
    },
    filterBox: {
      order: 1,
      direction: 'row',
      maxWidth: 'calc(100vw - 600px)',
    },
    filterElement: {
      marginRight: '8px',
    },
  },
  sm: {
    contentWrapper: {
      margin: '20px 10px',
      padding: '8px 10px',
    },
    actionsOuterWrapper: {
      direction: 'column',
      alignItems: 'center',
    },
    actionsInnerWrapper: {
      order: 1,
      marginBottom: '30px',
    },
    consultButtonWrapper: {
      margin: '5px 0 30px',
    },
    filterBox: {
      order: 2,
      direction: 'column',
    },
    filterElement: {
      marginRight: 0,
    },
  },
};

export const ContentWrapper = styled(MainWrapper)`
  margin: ${({ theme }) => theme.contentWrapper.margin};
  padding: ${({ theme }) => theme.contentWrapper.padding};
`;

export const ActionsOuterWrapper = styled(FlexBox)`
  flex-direction: ${({ theme }) => theme.actionsOuterWrapper.direction};
  justify-content: ${({ theme }) => theme.actionsOuterWrapper.horizontalAlign};
  align-items: ${({ theme }) => theme.actionsOuterWrapper.alignItems};
`;

export const ActionsInnerWrapper = styled(ActionsOuterWrapper)`
  order: ${({ theme }) => theme.actionsInnerWrapper.order};
  margin-bottom: ${({ theme }) => theme.actionsInnerWrapper.marginBottom};
`;

export const ConsultButtonWrapper = styled(Box)`
  margin: ${({ theme }) => theme.consultButtonWrapper.margin};
`;
