import { YES, NO } from 'utils/constants';
import { mapFormFieldsToApiFormat } from 'utils/mappers';
import { NetworkLabels } from 'utils/enums';

export const mobileImagingTypes = [
  { value: 'Rapid', text: 'Rapid' },
  { value: 'VizAl', text: 'Viz.AI' },
];

export const strokeCertificationOptions = [
  { value: 'CSC', text: 'CSC' },
  { value: 'TSC', text: 'TSC' },
  { value: 'PSC', text: 'PSC' },
  { value: 'ASR', text: 'ASR' },
  { value: 'None', text: 'None' },
];

export const hospitalSizeOptions = [
  { value: 'LessThen50Beds', text: '<50 beds' },
  { value: 'Between50And100Beds', text: '50-100 beds' },
  { value: 'Between100And500Beds', text: '100-500 beds' },
  { value: 'MoreThen500Beds', text: '>500 beds' },
];

export const thrombolyticValues = {
  Alteplase: 'Alteplase',
  Tenecteplase: 'Tenecteplase',
};

export const thrombolyticValuesOptions = Object.entries(thrombolyticValues).map(
  ([value, text]) => ({ value, text }),
);

export const networkFields = Object.keys(NetworkLabels).reduce(
  (res, network) => ({ ...res, [network]: `networks.${network}` }),
  {},
);

export const facilityFields = {
  active: 'active',
  name: 'name',
  street: 'address.streetAddress',
  city: 'address.locality',
  state: 'address.region',
  zipCode: 'address.postalCode',
  timezone: 'timezone',
  phone: 'edNumber',
  hospitalMainNumber: 'hospitalMainNumber',
  itPhone: 'itPhoneNumber',
  callCenterPhone: 'callCenterPhoneNumber',
  fax: 'faxNumber',
  emrName: 'emrName',
  emrLink: 'emrLink',
  directBeamIn: 'directBeamIn',
  eegSoftware: 'eegSoftware',
  eegSoftwareLink: 'eegSoftwareLink',
  radiology: 'radiology',
  radiologyLink: 'radiologyLink',
  sharedSignout: 'sharedSignout',
  sharedSignoutLink: 'sharedSignoutLink',
  camera: 'camera',
  cameraLink: 'cameraLink',
  mobileImagings: 'mobileImagings',
  note: 'note',
  isIntegrated: 'isIntegrated',
  r1Integration: 'r1Integration',
  hL7FacilityCode: 'hL7FacilityCode',
  strokeCertification: 'strokeCertification',
  hospitalSize: 'hospitalSize',
  codeFromSystem: 'inheritSiteCodeFromSystem',
  siteCode: 'siteCode',
  thrombolytic: 'thrombolytic',
  strokeCoverage: 'strokeCoverage',
  inpatientCoverage: 'inpatientCoverage',
  eegReader: 'eegReader',
  eegServices: 'eegServices',
  workflowDeviations: 'workflowDeviations',
  lvoProtocol: 'lvoProtocol',
  ...networkFields,
};

// Convert facility before sending a request to the API
export const mapFacilityToApiFormat = (facility) => ({
  ...mapFormFieldsToApiFormat({
    ...facility,
    isIntegrated: Boolean(facility.isIntegrated),
    r1Integration: Boolean(facility.r1Integration),
    directBeamIn: facility.directBeamIn === YES,
  }),
  networks: Object.entries(facility.networks).reduce(
    (res, [key, network]) =>
      network
        ? [
            ...res,
            {
              networkType: key,
              networkId: network,
            },
          ]
        : res,
    [],
  ),
  mobileImagings: Object.values(facility.mobileImagings),
});

// Convert facility before autofill form
export const mapFacilityToForm = (facility) => ({
  ...facility,
  directBeamIn:
    (facility.directBeamIn === false && NO) ||
    (facility.directBeamIn ? YES : null),
  networks: facility.networks.reduce(
    (res, network) => ({
      ...res,
      [network.networkType]: network.network.id,
    }),
    {},
  ),
});
