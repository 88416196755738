import fields from 'features/consults/shared/tabs/Demographics/formFields';
import {
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';
import { NeuroConsultTypes } from 'utils/enums';

export const leftPanel1 = ({ consultType }) => ({
  elements: [
    mapToggleFieldOptions(fields.consultType),
    mapToggleFieldOptions(fields.neuroPatientLocation),
    ...(consultType === NeuroConsultTypes.GENERAL
      ? [mapChipFieldOptions(fields.stat)]
      : []),
  ],
});

export const leftPanel2 = {
  elements: [
    fields.age,
    mapToggleFieldOptions(fields.gender),
    mapChipFieldOptions(fields.prehospitalBeamIn),
  ],
};

export const leftPanel3 = {
  elements: [
    fields.referringProvider,
    {
      group: [
        {
          ...fields.callBackPhone,
          xs: 8,
          sm: 8,
        },
        {
          ...fields.callBackPhoneExtension,
          xs: 3,
          sm: 3,
        },
      ],
    },
    fields.referringRN,
    {
      group: [
        {
          ...fields.rnCallbackNumber,
          xs: 8,
          sm: 8,
        },
        {
          ...fields.rnCallbackNumberExtension,
          xs: 3,
          sm: 3,
        },
      ],
    },
    fields.cameraName,
    fields.notes,
  ],
};

export const rightPanel = {
  elements: [fields.initialPageAt, fields.returnCallAt],
};

export const phoneOnlyPanel = {
  elements: [mapChipFieldOptions(fields.phoneOnly)],
};

export const phoneOnlyFreetextPanel = { elements: [fields.phoneOnlyFreetext] };

export const phoneAgreementPanel = {
  elements: [mapChipFieldOptions(fields.phoneAgreement)],
};
