import React from 'react';
import flatten from 'lodash/flatten';
import { v4 as uuid } from 'uuid';

import { RESET_OPTION } from 'features/consults/utils/constants';
import { ConsultFieldTypeEnum } from 'utils/enums';
import { MultiChipStatesEnum } from 'shared/components/forms/MultiChip/MultiChipStates.enum';
import {
  DEFAULT_FIELD_FORMAT,
  formatIsoDate,
  formatToSpecificTimezone,
} from 'utils/dates';
import { TIMEZONE_OFFSETS, TIMEZONE_LABELS } from 'utils/timezones';

const exactMatchPattern = /^\[\d+\] =/;

const updatePositiveOrNegativeString = (additionalFields) =>
  additionalFields
    ? additionalFields.map((item) => {
        if (item.startsWith('- +')) {
          return item.replace('- +', '- positive');
        }
        if (item.startsWith('- -')) {
          return item.replace('- -', '- negative');
        }
        return item;
      })
    : additionalFields;

const mapOptions = (options) =>
  options.reduce((res, option) => ({ ...res, [option.value]: option }), {});

const getChipSummaryValues = (options, selectedChips) => {
  const mappedOptions = mapOptions(options);
  return selectedChips
    ? selectedChips.map((selectedChip) => {
        const option = mappedOptions[selectedChip];
        if (!option) return null;
        const label = option.summaryLabel || option.label;
        return `- ${label}`;
      })
    : null;
};

const getMultiChipSummaryValues = (options, selectedChips) => {
  const mappedOptions = mapOptions(options);
  return selectedChips
    .map((selectedChip) => {
      const option = mappedOptions[selectedChip.label];
      const labelText = option.summaryLabel || option.label;

      if (option.summaryLabels) {
        const { positive, negative } = option.summaryLabels;
        if (selectedChip.state === MultiChipStatesEnum.YES) {
          if (positive) return positive;
        } else if (negative) {
          return negative;
        }
      } else if (selectedChip.state === MultiChipStatesEnum.NO) {
        const startsWithNegation = labelText.match(/^(not|non|no)\s/g);

        // If the option state is negative, check if the label starts with a negation (e.g. "no")
        // If it does, remove the negation
        if (startsWithNegation) {
          return labelText.substr(startsWithNegation[0].length);
        }
        if (labelText.match(/^(normal)/g)) {
          return `ab${labelText}`;
        }
        return `no ${labelText}`;
      }

      return labelText;
    })
    .map((value) => `- ${value}`);
};

export const getOptions4Type = (field, consultType) => ({
  ...field,
  options: field.options.filter(
    ({ showOn }) => !showOn || showOn.includes(consultType),
  ),
});

export const generateGroupFields = (generatorFunc, total = 6) =>
  Array.from({ length: total }).reduce(
    (acc, _, index) => ({
      ...acc,
      ...generatorFunc(index + 1),
    }),
    {},
  );

export const displaySummaryValue = (value, additionalValue) => {
  if (!additionalValue) return value;

  if (!value) return additionalValue;

  return [value, additionalValue];
};

export const mapFieldOptions = (options, fieldType) => {
  const labelFieldName =
    fieldType === ConsultFieldTypeEnum.TOGGLE ? 'content' : 'text';

  const fieldOptions = options.map(({ value, label, ...rest }) => ({
    value,
    [labelFieldName]: label,
    ...rest,
  }));

  if (fieldType === ConsultFieldTypeEnum.TOGGLE) {
    return [RESET_OPTION, ...fieldOptions];
  }

  return fieldOptions;
};

export const mapToggleFieldOptions = (toggleField) => ({
  ...toggleField,
  options: mapFieldOptions(toggleField.options, ConsultFieldTypeEnum.TOGGLE),
});

export const mapChipFieldOptions = (chipField) => ({
  ...chipField,
  options: mapFieldOptions(chipField.options, ConsultFieldTypeEnum.CHIP),
});

export const getFieldLabelByValue = (
  options,
  fieldValue,
  additionalValue,
  field,
) => {
  const option = options.find((item) => item.value === fieldValue);
  let strPrefix = additionalValue || field.associatedField ? '- ' : '';
  if (option && option.summaryLabel && strPrefix === '- ') {
    strPrefix = exactMatchPattern.test(option.summaryLabel || option.label)
      ? ''
      : '- ';
  }
  return option ? `${strPrefix}${option.summaryLabel || option.label}` : null;
};

export const getFieldSummaryLabel = (field, timezone) => {
  const { summaryLabel, label, title, type } = field;
  const labelText = summaryLabel || label || title;

  if (type === ConsultFieldTypeEnum.DATETIME) {
    return `${labelText} (${TIMEZONE_LABELS[timezone]}): `;
  }

  return labelText ? `${labelText}: ` : '';
};

export const getInitialValueByFieldType = (fieldType) => {
  switch (fieldType) {
    case ConsultFieldTypeEnum.CHIP:
    case ConsultFieldTypeEnum.MULTICHIP:
      return [];
    default:
      return null;
  }
};

export const getFieldSummaryValueForType = (field, timezone) => {
  const { type, value, additionalValue, options } = field;

  switch (type) {
    case ConsultFieldTypeEnum.INPUT:
    case ConsultFieldTypeEnum.PHONE:
    case ConsultFieldTypeEnum.DICTATION:
      return displaySummaryValue(value, additionalValue);

    case ConsultFieldTypeEnum.TOGGLE:
      return displaySummaryValue(
        getFieldLabelByValue(options, value, additionalValue, field),
        additionalValue,
      );

    case ConsultFieldTypeEnum.DATE:
      return formatIsoDate(value);

    case ConsultFieldTypeEnum.DATETIME:
      return formatToSpecificTimezone(
        new Date(value),
        DEFAULT_FIELD_FORMAT,
        TIMEZONE_OFFSETS[timezone],
      );

    case ConsultFieldTypeEnum.CHIP:
      return displaySummaryValue(
        getChipSummaryValues(options, value),
        additionalValue,
      );

    case ConsultFieldTypeEnum.MULTICHIP:
      return displaySummaryValue(
        getMultiChipSummaryValues(options, value),
        updatePositiveOrNegativeString(additionalValue),
      );
    case ConsultFieldTypeEnum.TITLE: {
      return displaySummaryValue('', additionalValue);
    }
    default:
      return null;
  }
};

const renderTextValue = (value) => {
  if (typeof value !== 'string') return value;

  const paragraphs = value.split('\n');
  return paragraphs.length === 1
    ? value
    : paragraphs.map((p) => <div key={uuid()}>{p || <br />}</div>);
};

export const renderFieldValue = ({
  field,
  facilityTimezone,
  facilityName,
  consult,
}) => {
  const fieldValue = getFieldSummaryValueForType(field, facilityTimezone);

  if (field.summaryContent) {
    return field.summaryContent({
      fieldValue: field.value,
      facilityName,
      consult,
    });
  }

  if (Array.isArray(fieldValue)) {
    return (
      <ul>
        {flatten(fieldValue).map((v) => (
          <li key={uuid()}>{v}</li>
        ))}
      </ul>
    );
  }

  return renderTextValue(fieldValue);
};
