import React from 'react';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import { FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import TableResultCount from 'shared/components/data/Table/TableResultCount';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import useAdminConsultsListState from 'features/admin/hooks/useAdminConsultsListState';

import ConsultListFilters from './ConsultListFilters';
import ConsultListButtons from './ConsultListButtons';

const ConsultList = () => {
  const navigate = useNavigate();
  const {
    page,
    pageCount,
    totalCount,
    sortBy,
    sortOrder,
    filters,
    loading,
    handleResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    handleApplyFilter,
    tableHeaders,
    tableData,
  } = useAdminConsultsListState();

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Consults</PageTitle>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ConsultListButtons
            filters={filters}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
          <TableResultCount totalCount={totalCount} />
        </Stack>
      </FlexBoxSpaceBetweenCenter>

      <ConsultListFilters
        filters={filters}
        onFilter={handleFilter}
        handleApplyFilter={handleApplyFilter}
        loading={loading}
        onResetFilters={handleResetFilters}
      />

      <FlexBoxColumn>
        <PaginatedTable
          headings={tableHeaders}
          data={tableData}
          fallbackMsg="No consults found"
          loading={loading}
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(id) => navigate(`${id}`)}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default ConsultList;
