import React from 'react';
import PropTypes from 'prop-types';

import {
  FlexBoxJustify,
  FlexBoxColumn,
  FlexBoxColumnSpaceBetween,
} from 'styles/layout';

import api from 'api';
import { useRequest, useMountEffect, useUpdateEffect } from 'shared/hooks';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { formatDateToStr } from 'utils/dates';

import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import SearchField, {
  SearchTitle,
} from 'shared/components/forms/SearchField/SearchField';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import Button from 'shared/components/buttons/Button/Button';
import { MatchTypeLabels } from 'utils/enums';

const tableColumns = [
  {
    header: {
      id: 'dtOfTrnsaction',
      value: 'DOS',
    },
    tooltip: true,
    formatValue: ({ dtOfTrnsaction }) => formatDateToStr(dtOfTrnsaction),
  },
  {
    header: {
      id: 'givenName',
      value: 'First Name',
    },
    tooltip: true,
    dataKey: 'givenName',
  },
  {
    header: {
      id: 'familyName',
      value: 'Last Name',
    },
    tooltip: true,
    dataKey: 'familyName',
  },
  {
    header: {
      id: 'dob',
      value: 'DOB',
    },
    tooltip: true,
    formatValue: ({ dob }) => formatDateToStr(dob),
  },
  {
    header: {
      id: 'matchType',
      value: 'Match Method',
    },
    width: '14%',
    formatValue: ({ matchType }) => MatchTypeLabels[matchType],
  },
  {
    header: {
      id: 'createdAt',
      value: 'Order Received',
    },
    formatValue: ({ createdAt }) => formatDateToStr(createdAt),
  },
];

const tableColumnsSidebar = [
  {
    header: {
      id: 'dtOfTrnsaction',
      value: 'DOS',
    },
    width: '25%',
    padding: '8px 5px',
    formatValue: ({ dtOfTrnsaction }) => formatDateToStr(dtOfTrnsaction),
  },
  {
    header: {
      id: 'givenName',
      value: 'First Name',
    },
    padding: '8px 5px',
    tooltip: true,
    dataKey: 'givenName',
  },
  {
    header: {
      id: 'familyName',
      value: 'Last Name',
    },
    padding: '8px 5px',
    tooltip: true,
    dataKey: 'familyName',
  },
  {
    header: {
      id: 'dob',
      value: 'DOB',
    },
    padding: '8px 5px',
    width: '25%',
    formatValue: ({ dob }) => formatDateToStr(dob),
  },
];

const ConsultIntegrationTable = ({
  consultId,
  consultType,
  onReview,
  last5Days,
  hideColumns = [],
  sidebarWidget = false,
}) => {
  const { doRequest, data: orderData } = useRequest(api.getOrders);

  const [page, setPage] = React.useState(1);
  const [searchTxt, setSearchTxt] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState(null);

  const onSearch = React.useCallback((searchInput) => {
    setPage(1);
    setSelectedRow(null);
    setSearchTxt(searchInput);
  }, []);

  const onClickRow = React.useCallback(
    (orderId) => {
      if (orderId === selectedRow?.id) {
        setSelectedRow(null);
        return;
      }
      const found = orderData?.hl7Orders?.find(({ id }) => id === orderId);
      setSelectedRow(found);
    },
    [orderData?.hl7Orders, selectedRow?.id],
  );

  const fetchOrders = React.useCallback(() => {
    doRequest({
      page,
      filters: {
        consultId,
        query: searchTxt,
        type: consultType,
        showCreatedLastFiveDays: last5Days,
      },
      sortBy: 'CreatedAt',
      sortOrder: 'desc',
    });
  }, [doRequest, page, consultId, consultType, searchTxt, last5Days]);

  useMountEffect(fetchOrders);

  useUpdateEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const filteredColumns = React.useMemo(
    () =>
      sidebarWidget
        ? tableColumnsSidebar
        : tableColumns.filter(
            (column) => !hideColumns.includes(column.header.id),
          ),
    [hideColumns, sidebarWidget],
  );

  if (!orderData) return <LoadingSpinner />;

  return (
    <FlexBoxColumnSpaceBetween sx={{ height: '100%' }}>
      <FlexBoxColumn sx={{ flexGrow: 1 }}>
        <FlexBoxColumn mb={3}>
          <SearchTitle>Unmatched ADT Orders</SearchTitle>
          <SearchField onChange={onSearch} />
        </FlexBoxColumn>
        <PaginatedTable
          headings={getTableHeaders(filteredColumns)}
          data={prepareTableData(orderData?.hl7Orders, filteredColumns)}
          fallbackMsg="No orders to show"
          selectedRowId={selectedRow?.id}
          onClickRow={onClickRow}
          currentPage={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          totalPages={orderData?.pageCount}
        />
      </FlexBoxColumn>
      <FlexBoxJustify mt={3}>
        <Button
          disabled={!selectedRow}
          variant="contained"
          color="secondary"
          onClick={() => onReview(selectedRow)}
        >
          Review Order & Consult
        </Button>
      </FlexBoxJustify>
    </FlexBoxColumnSpaceBetween>
  );
};

ConsultIntegrationTable.propTypes = {
  consultId: PropTypes.string.isRequired,
  consultType: PropTypes.string.isRequired,
  onReview: PropTypes.func.isRequired,
  last5Days: PropTypes.bool,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  sidebarWidget: PropTypes.bool,
};

export default ConsultIntegrationTable;
