import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { consultsActions } from 'features/consults/store/slice';
import { DEBOUNCE_TIMEOUT } from 'utils/constants';
import { Autocomplete } from 'shared/components/forms/Autocomplete/styles';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import Button from 'shared/components/buttons/Button/Button';
import { Box, FlexBoxColumn, FlexBoxSpaceBetween } from 'styles/layout';

import formFields from 'features/consults/shared/tabs/Signature/formFields';

const SEARCH_URL =
  'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&maxList=&terms=';

const fetchEntries = async (searchTerm) =>
  axios.get(`${SEARCH_URL}${searchTerm}`);

const inputDefaults = {
  size: 'small',
  placeholder: 'ICD Select',
  label: formFields.icdCodes.label,
};

const IcdList = ({ items, onDelete, disabled }) => {
  if (!items?.length) return null;
  return (
    <Stack mt={1} spacing={1} direction="row" useFlexGap flexWrap="wrap">
      {items.map(({ id, code, description }) => (
        <Chip
          key={uuid()}
          label={`${code} - ${description}`}
          onDelete={() => onDelete(id)}
          disabled={disabled}
        />
      ))}
    </Stack>
  );
};

IcdList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const ConsultIcd = ({
  consultId,
  consultData: { icdCodes },
  inputProps: { size, placeholder, label } = inputDefaults,
  staticOptions,
  defaultOptions,
  disabled,
  onSetValue,
  getFieldError,
  required = false,
  isMacro,
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState([]);
  const [icd2Add, setIcd2Add] = React.useState(null);

  const error = React.useMemo(
    () => getFieldError(formFields.icdCodes.name),
    [getFieldError],
  );

  React.useEffect(() => {
    onSetValue?.(formFields.icdCodes.name, icdCodes);
  }, [icdCodes, onSetValue]);

  const handleSearch = debounce((searchTerm) => {
    if (staticOptions) return;
    if (!searchTerm?.length) {
      setOptions(null);
      return;
    }
    fetchEntries(searchTerm).then(({ data }) => {
      const consultCodes = icdCodes?.map((icd) => icd.code) ?? [];
      setOptions(
        data[3]
          .map(([code, name]) => ({ code, name }))
          .filter(({ code }) => !consultCodes.includes(code)),
      );
    });
  }, DEBOUNCE_TIMEOUT);

  const handleAddCode = React.useCallback(() => {
    dispatch(
      consultsActions.addIcd({
        code: icd2Add.code,
        description: icd2Add.name,
        [isMacro ? 'consultMacroId' : 'consultId']: consultId,
        isMacro,
      }),
    );
    setIcd2Add(null);
  }, [consultId, icd2Add, dispatch, isMacro]);

  const handleDeleteCode = React.useCallback(
    (id) => {
      dispatch(consultsActions.deleteIcd({ id, isMacro }));
    },
    [dispatch, isMacro],
  );

  const selectOptions = React.useMemo(() => {
    if (staticOptions) return staticOptions;
    return [
      ...(defaultOptions?.map((opt) => ({ ...opt, group: 'Common options' })) ||
        []),
      ...(options?.map((opt) => ({
        ...opt,
        group: 'Searched options',
      })) || []),
    ];
  }, [staticOptions, defaultOptions, options]);

  return (
    <FlexBoxColumn>
      {!!label && (
        <InputLabel required={required} error={error}>
          {label}
        </InputLabel>
      )}
      <FlexBoxSpaceBetween>
        <Box sx={{ flexGrow: 1 }}>
          <Autocomplete
            value={icd2Add}
            disabled={disabled}
            size={size}
            options={selectOptions || []}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => `${option.code} - ${option.name}`}
            onChange={(_, icd) => setIcd2Add(icd)}
            onInputChange={(e) => handleSearch(e?.target?.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                placeholder={placeholder}
              />
            )}
          />
        </Box>

        <FlexBoxColumn sx={{ marginLeft: '5px', justifyContent: 'flex-end' }}>
          <Button
            sx={{ height: '2.5rem' }}
            variant="contained"
            disabled={disabled || !icd2Add}
            onClick={handleAddCode}
          >
            Add
          </Button>
        </FlexBoxColumn>
      </FlexBoxSpaceBetween>
      <IcdList
        items={icdCodes}
        onDelete={handleDeleteCode}
        disabled={disabled}
      />
      {error && <ErrorMsg text={error.message} dense={size === 'small'} />}
    </FlexBoxColumn>
  );
};

ConsultIcd.propTypes = {
  inputProps: PropTypes.shape({
    size: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
  }),
  consultId: PropTypes.string.isRequired,
  consultData: PropTypes.shape({
    icdCodes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  required: PropTypes.bool,
  isMacro: PropTypes.bool,
  staticOptions: PropTypes.arrayOf(PropTypes.shape({})),
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  onSetValue: PropTypes.func,
  getFieldError: PropTypes.func,
};

export default ConsultIcd;
