import React from 'react';

import sortFields from 'api/sortFields';

import IntegrationIcon from 'shared/components/icons/IntegrationIcon';
import ConsultFlag from 'features/consults/components/ConsultFlag/ConsultFlag';
import { US_DATE_FORMAT, TIME_FORMAT, formatToUserTimezone } from 'utils/dates';
import { mapPhoneNumberToMask } from 'utils/mappers';
import { showFirstLetter } from 'utils/helpers';
import { EMPTY_COLUMN_VALUE } from 'utils/constants';
import {
  ServiceTypeEnum,
  NeuroConsultLabels,
  TeleNeuroConsultLabels,
  IntegrationTypesEnum,
} from 'utils/enums';

const integrationColumn = (consultType) => {
  const showColumn = consultType === ServiceTypeEnum.NEURO;
  return {
    header: { id: 'integration', value: '' },
    formatValue: ({ integrationStatus, integrationTypes }) => {
      if (!showColumn || !integrationTypes?.includes(IntegrationTypesEnum.bi))
        return null;

      return <IntegrationIcon integrationStatus={integrationStatus} />;
    },
    width: showColumn ? '3.5rem' : '0',
  };
};

const urgencyColumn = (consultType) => {
  const shouldShow = [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(
    consultType,
  );
  return {
    header: {
      id: sortFields.consults.urgency,
      value: shouldShow ? 'Urgency' : '',
      isSortable: true,
    },
    formatValue: (item) =>
      shouldShow ? (item.eegStatOption && 'STAT') || EMPTY_COLUMN_VALUE : '',
    width: shouldShow ? '100px' : '0px',
  };
};

const mapConsultType = (item, consultType) => {
  switch (consultType) {
    case ServiceTypeEnum.NEURO:
      return NeuroConsultLabels[item.neuroConsultTypeName];
    case ServiceTypeEnum.TELE_NEURO:
      return TeleNeuroConsultLabels[item.teleNeuroHospitalConsultTypeName];
    case ServiceTypeEnum.EEG:
      return item.eegConsultTypeName;
    case ServiceTypeEnum.PEDS_EEG:
      return item.eegPediatricConsultTypeName;
    default:
      return null;
  }
};

const consultColor = (consultType) => ({
  header: {
    id: sortFields.consults.color,
    value: '',
  },
  formatValue: (item) => (
    <ConsultFlag
      consultId={item?.id}
      consultType={consultType}
      initialFlag={item.colorName}
    />
  ),
  width: '15px',
  empty: true,
});

const patientInitials = {
  header: {
    id: 'PatientInitials',
    value: '',
  },
  formatValue: ({ patientFirstName, patientLastName }) => {
    let initials = EMPTY_COLUMN_VALUE;

    if (patientFirstName || patientLastName) {
      initials = `${showFirstLetter(patientFirstName)}${showFirstLetter(
        patientLastName,
      )}`;
    }

    return initials;
  },
  width: '60px',
};

export const facilityName = {
  header: {
    id: sortFields.consults.facilityName,
    value: 'Facility',
    isSortable: true,
  },
  formatValue: (item) => item.facilityName || EMPTY_COLUMN_VALUE,
};

export const consultsTableColumns = (consultType) => [
  consultColor(consultType),
  patientInitials,
  facilityName,
  {
    header: {
      id: sortFields.consults.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'patientFirstName',
    width: '10%',
  },
  {
    header: {
      id: sortFields.consults.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'patientLastName',
    width: '10%',
  },
  {
    header: {
      id: sortFields.consults.type,
      value: consultType === ServiceTypeEnum.CLINIC ? '' : 'Type',
      isSortable: true,
    },
    formatValue: (item) => mapConsultType(item, consultType) || '',
    width: consultType === ServiceTypeEnum.CLINIC ? '0' : null,
  },
  urgencyColumn(consultType),
  {
    header: {
      id: sortFields.consults.phone,
      value: 'Phone',
      isSortable: true,
    },
    formatValue: (item) =>
      mapPhoneNumberToMask(item.phone) || EMPTY_COLUMN_VALUE,
    width: '150px',
  },
  {
    header: {
      id: sortFields.consults.phoneExtension,
      value: 'Ext.',
      isSortable: true,
    },
    formatValue: (item) => item.phoneExtension || EMPTY_COLUMN_VALUE,
    width: '150px',
  },
  {
    header: {
      id: sortFields.consults.assignTo,
      value: 'Provider',
      isSortable: true,
    },
    formatValue: ({ assignToFirstName, assignToLastName }) =>
      assignToFirstName
        ? `${assignToFirstName} ${assignToLastName}`
        : EMPTY_COLUMN_VALUE,
    width: '15%',
  },
  {
    header: {
      id: sortFields.consults.createdAt,
      value: 'Created At',
      isSortable: true,
    },
    formatValue: (item) =>
      formatToUserTimezone(
        new Date(item.createdAt),
        `${US_DATE_FORMAT} - ${TIME_FORMAT}`,
      ),
  },
  integrationColumn(consultType),
];

export const smallConsultsTableColumns = (consultType) => [
  consultColor(consultType),
  patientInitials,
  facilityName,
  integrationColumn(consultType),
];
