import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';

import { Box } from 'styles/layout';
import colors from 'styles/colors';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import Autocomplete from 'shared/components/forms/Autocomplete';
import DictationInput from 'shared/components/forms/DictationInput';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import DateTimerPicker from 'shared/components/forms/DateTimerPicker';
import SingleSelect from 'features/consults/components/tabFields/SingleSelect/SingleSelect';
import MultiSelect from 'features/consults/components/tabFields/MultiSelect/MultiSelect';
import MultiChipSelect from 'features/consults/components/tabFields/MultiChipSelect';
import PhoneNumber from 'features/consults/components/tabFields/PhoneNumber/PhoneNumber';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import { ConsultFieldTypeEnum } from 'utils/enums';

const SectionHeader = styled(InputLabel)`
  color: ${colors.neutralDark};
  font-weight: 600;
`;

export const PanelField = ({
  name,
  type,
  options,
  register,
  control,
  onChange,
  getFieldError,
  inputType = 'text',
  ...rest
}) => {
  switch (type) {
    case ConsultFieldTypeEnum.INPUT:
      return (
        <TextInput
          {...rest}
          type={inputType}
          name={name}
          register={register}
          onChange={onChange}
          getFieldError={getFieldError}
        />
      );

    case ConsultFieldTypeEnum.DICTATION:
      return (
        <DictationInput
          {...rest}
          name={name}
          control={control}
          onChange={onChange}
        />
      );

    case ConsultFieldTypeEnum.TOGGLE:
      return (
        <SingleSelect
          {...rest}
          name={name}
          control={control}
          buttons={options}
          compact
          onChange={onChange}
          error={getFieldError(name)}
        />
      );

    case ConsultFieldTypeEnum.CHIP:
      return (
        <MultiSelect
          {...rest}
          name={name}
          options={options}
          control={control}
          onChange={onChange}
          error={getFieldError(name)}
        />
      );

    case ConsultFieldTypeEnum.MULTICHIP:
      return (
        <MultiChipSelect
          {...rest}
          name={name}
          options={options}
          control={control}
          onChange={onChange}
        />
      );

    case ConsultFieldTypeEnum.DATETIME:
      return (
        <DateTimerPicker
          {...rest}
          name={name}
          control={control}
          onChange={() => {
            onChange(name, ConsultFieldTypeEnum.DATETIME);
          }}
          error={getFieldError(name)}
        />
      );

    case ConsultFieldTypeEnum.DATE:
      return (
        <DatePicker
          {...rest}
          name={name}
          control={control}
          error={getFieldError(name)}
          handleChange={() => onChange(name)}
        />
      );

    case ConsultFieldTypeEnum.PHONE:
      return (
        <PhoneNumber
          {...rest}
          control={control}
          name={name}
          getFieldError={getFieldError}
          onChange={onChange}
        />
      );
    case ConsultFieldTypeEnum.AUTOCOMPLETE:
      return (
        <Autocomplete
          {...rest}
          control={control}
          name={name}
          options={options.map((opt) => ({ id: opt.value, name: opt.label }))}
          error={getFieldError(name)}
          handleChange={() => onChange(name)}
        />
      );

    default:
      return <Box sx={{ color: 'red' }}>{`UNKNOW INPUT ${type}`}</Box>;
  }
};

PanelField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  getFieldError: PropTypes.func,
  inputType: PropTypes.string,
};

const QuestionsPanel = ({
  label = null,
  required = false,
  elements = [],
  disableElements = [],
  title = null,
  mb = 1,
  disabled,
  ...rest
}) => (
  <Box mb={mb}>
    {!!title && (
      <Box mb={2}>
        <SectionHeader>{title}</SectionHeader>
      </Box>
    )}
    {!!label && <InputLabel required={required}>{label}</InputLabel>}
    {elements.map(
      ({
        associatedField,
        summaryLabel,
        excludeFromSummary,
        summaryContent,
        name,
        group = null,
        ...fieldProps
      }) => {
        if (group) {
          return (
            <Box key={name || group.map((f) => f.name).join('_')} mb={0.5}>
              <Grid container justifyContent="space-between">
                {group.map(({ xs = 6, sm = 6, ...groupField }) => (
                  <Grid item key={groupField.name} xs={xs} sm={sm}>
                    <PanelField
                      {...rest}
                      {...fieldProps}
                      {...groupField}
                      name={groupField.name}
                      disabled={
                        groupField.disabled ||
                        disabled ||
                        disableElements.includes(groupField.name)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          );
        }
        return (
          <Box key={name} mb={0.5}>
            <PanelField
              {...rest}
              {...fieldProps}
              name={name}
              disabled={
                fieldProps.disabled ||
                disabled ||
                disableElements.includes(name)
              }
            />
          </Box>
        );
      },
    )}
  </Box>
);

QuestionsPanel.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  mb: PropTypes.number,
  required: PropTypes.bool,
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  disableElements: PropTypes.arrayOf(PropTypes.string),
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export default QuestionsPanel;
