import React from 'react';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import api from 'api';
import {
  useMountEffect,
  useUpdateEffect,
  useFilterState,
  useTableState,
  useRequest,
  useFeedback,
} from 'shared/hooks';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { formatDateToStr } from 'utils/dates';
import { MatchTypeLabels } from 'utils/enums';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { downloadFile } from 'utils/helpers';

import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import TableResultCount from 'shared/components/data/Table/TableResultCount';
import DownloadButton from 'shared/components/buttons/DownloadButton';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import { FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';

import { OrderListFilters, FilterFields } from './OrderListFilters';

const tableColumns = [
  {
    header: {
      id: 'givenName',
      value: 'Given Name',
      isSortable: true,
    },
    dataKey: 'givenName',
  },
  {
    header: {
      id: 'familyName',
      value: 'Family Name',
      isSortable: true,
    },
    dataKey: 'familyName',
  },
  {
    header: {
      id: 'dob',
      value: 'DOB',
      isSortable: true,
    },
    width: '10%',
    formatValue: ({ dob }) => formatDateToStr(dob),
  },
  {
    header: {
      id: 'sendingFacility',
      value: 'Sending Facility',
      isSortable: true,
    },
    dataKey: 'sendingFacilityNames',
  },
  {
    header: {
      id: 'matchType',
      value: 'Match Method',
      isSortable: true,
    },
    width: '14%',
    formatValue: ({ matchType }) => MatchTypeLabels[matchType],
  },
  {
    header: {
      id: 'createdAt',
      value: 'Order Received',
      isSortable: true,
    },
    width: '14%',
    formatValue: ({ createdAt }) => formatDateToStr(createdAt),
  },
];

const OrderListPage = () => {
  const { data: tableData, doRequest } = useRequest(api.getAdminOrderList);
  const navigate = useNavigate();
  const { clearDisplay, errorDisplay } = useFeedback();
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
    setQueryParams,
  } = useTableState('CreatedAt', 'desc');

  const { filters, handleFilter } = useFilterState(
    Object.values(FilterFields).reduce(
      (res, { name }) => ({ ...res, [name]: null }),
      {},
    ),
    setPage,
  );

  const fetchList = React.useCallback(() => {
    doRequest({
      page,
      sortBy,
      sortOrder,
      filters,
    });
    setQueryParams({
      page,
      sortBy,
      sortOrder,
      ...filters,
    });
  }, [doRequest, setQueryParams, page, sortBy, sortOrder, filters]);

  const onExport = React.useCallback(async () => {
    clearDisplay();
    try {
      const { data, headers } = await api.exportOrders({
        filters,
        sortBy,
        sortOrder,
        pageSize: MAX_PAGE_SIZE,
      });
      downloadFile(data, headers);
    } catch {
      errorDisplay('Error downloading file');
    }
  }, [sortBy, sortOrder, filters, clearDisplay, errorDisplay]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter mb={1}>
        <PageTitle>Orders</PageTitle>
        <Stack spacing={2} direction="row" alignItems="center">
          <DownloadButton title="Download CSV" onExport={onExport} />
          <TableResultCount totalCount={tableData?.count} />
        </Stack>
      </FlexBoxSpaceBetweenCenter>
      {tableData ? (
        <>
          <OrderListFilters filters={filters} handleFilter={handleFilter} />
          <FlexBoxColumn mt={3}>
            <PaginatedTable
              headings={getTableHeaders(tableColumns)}
              data={prepareTableData(tableData?.data || [], tableColumns)}
              fallbackMsg="No Orders found"
              sortBy={sortBy}
              sortOrder={sortOrder}
              currentPage={page}
              totalPages={tableData?.pageCount}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              onClickRow={(id) => navigate(`${id}`)}
            />
          </FlexBoxColumn>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </FlexBoxColumn>
  );
};

export default OrderListPage;
