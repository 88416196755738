import React from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';

import { useRequest, useMountEffect, useFormState } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';
import BackButton from 'navigation/components/BackButton/BackButton';
import { ServiceTypeEnum } from 'utils/enums';
import ConsultRequestForm from 'features/customer/pages/NewConsultRequestPage/ConsultRequestForm';
import {
  consultTypeOptions,
  formFields,
} from 'features/customer/pages/NewConsultRequestPage/data';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import { FlexBoxColumn, FlexBoxAlign } from 'styles/layout';

const getRequestType = (request) =>
  request.type === ServiceTypeEnum.NEURO
    ? request.neuroConsultType
    : request.type;

const mapRequest2Form = (request) => ({
  ...request,
  [formFields.facility]: request.facilityName,
  [formFields.consultType]: getRequestType(request),
  [formFields.neuroHospitalConsultType]: request.neuroHospitalistConsultType,
  [formFields.neuroDirectBeamInRequested]: request.directBeamInRequested
    ? ['directBeamIn']
    : [],
  [formFields.statReadRequested]: request[formFields.statReadRequested]
    ? [formFields.statReadRequested]
    : [],
});

const ConsultRequestPage = () => {
  const { consultRequestId } = useParams();
  const { goBack } = useGoBack();
  const { doRequest, data } = useRequest(api.getConsultRequestById);

  const { setFormValues, ...form } = useFormState(null, {
    stateSlice: 'consultRequests',
    showAsSnackbar: true,
  });

  useMountEffect(() => {
    doRequest(consultRequestId);
  });

  React.useEffect(() => {
    if (!data) return;
    setFormValues(mapRequest2Form(data.consultRequest));
  }, [data, setFormValues]);

  const facilities = React.useMemo(
    () =>
      data?.consultRequest
        ? [
            {
              id: data.consultRequest.facilityName,
              name: data.consultRequest.facilityName,
              directBeamIn: data.consultRequest.directBeamInRequested
            },
          ]
        : [],
    [data],
  );

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton onBack={goBack} />
        <PageTitle>Consult Request Details</PageTitle>
      </FlexBoxAlign>
      <ConsultRequestForm
        readOnly
        formProps={form}
        facilities={facilities}
        typeOptions={consultTypeOptions}
      />
    </FlexBoxColumn>
  );
};

export default ConsultRequestPage;
