import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AccessTime } from '@mui/icons-material';
import styled from '@emotion/styled';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import { FlexBoxAlignWrap } from 'styles/layout';
import { formatToBrowserDate, parseDateStr, US_DATE_FORMAT } from 'utils/dates';
import { DEBOUNCE_TIMEOUT } from 'utils/constants';

const Picker = styled(MuiDatePicker)`
  & input {
    padding: 9px 0 9px 14px;
  }
`;

const DatePicker = ({
  control,
  name,
  label,
  required = false,
  disabled = false,
  error = null,
  format = US_DATE_FORMAT,
  openTo = 'day',
  disableFuture = false,
  handleChange,
  hasSetNowButton = false,
}) => {
  const maxDate = disableFuture ? new Date() : undefined;
  const debouncedChange = debounce((value) => {
    if (handleChange) handleChange(value);
  }, DEBOUNCE_TIMEOUT);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ field }) => (
        <>
          <FlexBoxAlignWrap>
            <InputLabel
              htmlFor={name}
              required={required}
              disabled={disabled}
              error={!!error}
              inline
            >
              {label}
            </InputLabel>
            {hasSetNowButton && (
              <IconButton
                disabled={disabled}
                onClick={() => {
                  const newDate = new Date();
                  field.onChange(formatToBrowserDate(newDate));
                  if (handleChange) handleChange(newDate);
                }}
              >
                <AccessTime />
              </IconButton>
            )}
            <Picker
              {...field}
              value={
                field.value ? parseDateStr(field.value.substring(0, 10)) : null
              }
              slotProps={{
                textField: {
                  onChange: (value, args) => {
                    if (args?.validationError) return;
                    const newValue = formatToBrowserDate(value);
                    field.onChange(newValue);
                    debouncedChange(value);
                  },
                },
              }}
              required={required}
              disabled={disabled}
              format={format}
              openTo={openTo}
              disableFuture={disableFuture}
              maxDate={maxDate}
              error={!!error}
              onChange={(value, args) => {
                if (args?.validationError) return;
                const newValue = formatToBrowserDate(value);
                field.onChange(newValue);
                if (handleChange) handleChange(value);
              }}
            />
          </FlexBoxAlignWrap>
          {error && <ErrorMsg text={error.message} dense />}
        </>
      )}
    />
  );
};

DatePicker.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  format: PropTypes.string,
  placeholder: PropTypes.string,
  openTo: PropTypes.string,
  disableFuture: PropTypes.bool,
  handleChange: PropTypes.func,
  hasSetNowButton: PropTypes.bool,
};

export default DatePicker;
