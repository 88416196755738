import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { Typography } from '@mui/material';

import { Box, FlexBoxAlign } from 'styles/layout';
import colors from 'styles/colors';

const BillingInfoOrder = ({ data }) => {
  const billingDetails = React.useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map((item, index) => ({
      id: index,
      details: Object.entries(item),
    }));
  }, [data]);

  return (
    <Box
      sx={{
        backgroundColor: colors.neutral,
        padding: 2,
        overflow: 'auto',
        height: '100%',
      }}
    >
      {billingDetails.length ? (
        billingDetails.map(({ id, details }) => (
          <Box key={id} sx={{ marginBottom: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '10px' }}>
              Entry {id + 1}:
            </Typography>
            {details.map(([key, value]) => (
              <FlexBoxAlign key={key} sx={{ marginBottom: '10px' }}>
                <Typography variant="h6" mr={1}>
                  {`${startCase(key.toLowerCase())}:`}
                </Typography>
                <Typography>
                  {typeof value === 'object'
                    ? JSON.stringify(value)
                    : value || 'N/A'}
                </Typography>
              </FlexBoxAlign>
            ))}
          </Box>
        ))
      ) : (
        <Typography variant="h6">No data available</Typography>
      )}
    </Box>
  );
};

BillingInfoOrder.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default BillingInfoOrder;
