import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { FlexBoxSpaceBetweenCenter } from 'styles/layout';
import colors from 'styles/colors';
import { facilityFields } from 'features/admin/components/FacilityForm';

const coverageModelData = [
  {
    label: 'Stroke Coverage',
    name: facilityFields.strokeCoverage
  },
  {
    label: 'Inpatient Coverage',
    name: facilityFields.inpatientCoverage
  },
  {
    label: 'EEG Reader',
    name: facilityFields.eegReader
  },
  {
    label: 'EEG Services',
    name: facilityFields.eegServices
  }
]

const FacilityInfoLabel = styled.span`
  color: ${colors.neutralDark};
  font-weight: 500;
  font-size: 12px;
`;

const FacilityInfoValue = styled.span`
  color: ${colors.neutralDark};
  font-size: 12px;
`;


const CoverageModels = ({ consultFacility }) => 
  coverageModelData.map(({ label, name }) => (
    <FlexBoxSpaceBetweenCenter key={label} mb={1}>
      <FacilityInfoLabel>{label}</FacilityInfoLabel>
      <FacilityInfoValue>{consultFacility?.[name] ?? ''}</FacilityInfoValue>
    </FlexBoxSpaceBetweenCenter>
  ));

  
CoverageModels.propTypes = {
  consultFacility: PropTypes.shape({}),
};

export default CoverageModels;
