import { ServiceTypeEnum, EegConsultTypes } from 'utils/enums';
import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import { eegDemographicsFields as fields } from 'features/consults/shared/tabs/Demographics/formFields';

export const eEGTypeFields = (consultType, getValues) => {
  const typeField =
    consultType === ServiceTypeEnum.PEDS_EEG
      ? fields.eegPedsConsultType
      : fields.consultType;
  const typeFieldValue = getValues(typeField.name);
  return [
    mapToggleFieldOptions(typeField),
    ...([EegConsultTypes.CEEG, EegConsultTypes.CERIBELL].includes(
      typeFieldValue,
    )
      ? [mapToggleFieldOptions(fields.observationType)]
      : []),
    mapToggleFieldOptions(fields.videoType),
  ];
};

export const demographicFields1 = [
  mapToggleFieldOptions(fields.eegPatientLocation),
  mapChipFieldOptions(fields.eegStatOptions),
];

export const getDemographicsSecondType = (consultType) => {
  const baseFields = [fields.age, mapToggleFieldOptions(fields.gender)];
  if (consultType === ServiceTypeEnum.PEDS_EEG) {
    return [...baseFields, fields.gestationalAge];
  }
  return baseFields;
};

export const demographicFields3 = [
  fields.referringProvider,
  {
    group: [
      {
        ...fields.callBackPhone,
        xs: 8,
        sm: 8,
      },
      {
        ...fields.callBackPhoneExtension,
        xs: 3,
        sm: 3,
      },
    ],
  },
  fields.referringTechnician,
  {
    group: [
      {
        ...fields.techCallbackNumber,
        xs: 8,
        sm: 8,
      },
      {
        ...fields.techCallbackNumberExtension,
        xs: 3,
        sm: 3,
      },
    ],
  },
  fields.notes,
];

export const demographicFields4 = [fields.initialPageAt, fields.returnCallAt];
