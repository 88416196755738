import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';

import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import AccessTime from '@mui/icons-material/AccessTime';

import { DEBOUNCE_TIMEOUT } from 'utils/constants';
import { utcToZonedTime, zonedTimeToUtc } from 'utils/dates';
import { TIMEZONE_LABELS, TimezoneStrings } from 'utils/timezones';

import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import IconButton from 'shared/components/buttons/IconButton/IconButton';

import colors from 'styles/colors';
import { FlexBoxAlignWrap, FlexBoxAlign } from 'styles/layout';

const formatToISOString = (date) => {
  if (!date) return null;
  date.setSeconds(0, 0);
  return date.toISOString();
};

/* TODO: change this styled to Picker props */
const MuiPicker = styled(DesktopDateTimePicker)`
  .MuiInputBase-input {
    padding: 9px 0 9px 14px;
  }
  .MuiInputAdornment-positionEnd {
    margin-right: -5px !important;
  }
`;

const CustomDigitalClockSectionItem = ({ children, selected, ...props }) => {
  const itemRef = React.useRef(null);

  React.useEffect(() => {
    if (selected && itemRef.current) {
      const parent = itemRef.current.parentElement;
      if (!parent) return;
      const parentHeight = parent.offsetHeight;
      const itemHeight = itemRef.current.offsetHeight;
      const itemTop = itemRef.current.offsetTop;
      setTimeout(() => {
        parent.scrollTop = itemTop - parentHeight / 2 + itemHeight / 2;
      }, 10);
    }
  }, [selected]);

  return (
    <MenuItem
      {...props}
      ref={itemRef}
      selected={selected}
      sx={{
        backgroundColor: selected && `${colors.primary} !important`,
        color: selected && 'white',
      }}
    >
      {children}
    </MenuItem>
  );
};

CustomDigitalClockSectionItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
};

const CustomInput = ({ InputProps, placeholder, ...props }) => {
  const inputProps = React.useMemo(
    () => ({
      ...InputProps,
      placeholder: InputProps.placeholder || placeholder,
    }),
    [InputProps, placeholder],
  );

  return (
    <TextField {...props} InputProps={inputProps} placeholder={placeholder} />
  );
};

CustomInput.propTypes = {
  InputProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
};

const DateTimePicker = ({
  name,
  placeholder,
  control,
  onChange,
  label,
  disabled,
  required,
  timezone,
  error,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [isCalendarClick, setCalendarClick] = React.useState(false);

  const debouncedHandleChange = React.useMemo(
    () => debounce(onChange, DEBOUNCE_TIMEOUT),
    [onChange],
  );

  const tzString = React.useMemo(() => TimezoneStrings[timezone], [timezone]);

  const currentValue = React.useCallback(
    (value) => {
      if (!value) return null;
      return tzString ? utcToZonedTime(value, tzString) : new Date(value);
    },
    [tzString],
  );

  const handleChange = React.useCallback(
    (field, value, debounced) => {
      const saveDate =
        tzString && value ? zonedTimeToUtc(value, tzString) : value;
      const saveValue = formatToISOString(saveDate);
      field.onChange(saveValue);
      return debounced ? debouncedHandleChange(saveValue) : onChange(saveValue);
    },
    [onChange, debouncedHandleChange, tzString],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FlexBoxAlignWrap>
          {label && (
            <FlexBoxAlign style={{ gap: '0.5rem' }}>
              <InputLabel
                htmlFor={name}
                disabled={disabled}
                required={required}
                error={!!error}
              >
                {`${label} ${timezone ? `(${TIMEZONE_LABELS[timezone]})` : ''}`}
              </InputLabel>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  handleChange(
                    field,
                    tzString
                      ? utcToZonedTime(new Date(), tzString)
                      : new Date(),
                  );
                }}
              >
                <AccessTime />
              </IconButton>
            </FlexBoxAlign>
          )}
          <MuiPicker
            {...props}
            name={name}
            value={currentValue(field.value)}
            disabled={disabled}
            disableOpenPicker
            open={open}
            onClose={() => setOpen(false)}
            slots={{
              textField: CustomInput,
              digitalClockSectionItem: CustomDigitalClockSectionItem,
            }}
            onChange={(value) => {
              if (isCalendarClick && !field.value) {
                const current = tzString
                  ? utcToZonedTime(new Date(), tzString)
                  : new Date();
                value.setHours(current.getHours());
                value.setMinutes(current.getMinutes());
              }
              handleChange(field, value);
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                onClick: () => setOpen(true),
                onChange: (value, { validationError }) => {
                  if (validationError) return;
                  handleChange(field, value, true);
                },
                InputProps: { placeholder },
              },
              clearButton: {
                size: 'small',
                sx: {
                  color: colors.white,
                  '&:hover': {
                    backgroundColor: colors.primary,
                  },
                },
              },
              day: () => ({
                onPointerEnter: () => {
                  setCalendarClick(true);
                },
                onPointerLeave: () => {
                  setCalendarClick(false);
                },
              }),
              layout: {
                sx: {
                  ul: {
                    '::-webkit-scrollbar': {
                      width: '10px',
                    },
                    '::-webkit-scrollbar-track': {
                      background: (theme) => theme.palette.grey[100],
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: (theme) => theme.palette.primary.light,
                      borderRadius: '4px',
                    },
                  },
                },
              },
            }}
            ampm={false}
            timeSteps={{ minutes: 1 }}
          />
        </FlexBoxAlignWrap>
      )}
    />
  );
};

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  timezone: PropTypes.string,
  error: PropTypes.string,
};

export const DateTimeFilter = ({ name, onChange, ...props }) => (
  <DateTimePicker
    {...props}
    name={name}
    onChange={(value) => {
      onChange({
        [name]: value,
      });
    }}
  />
);

DateTimeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default DateTimePicker;
