import React from 'react';
import PropTypes from 'prop-types';

import { OutlinedInput } from '@mui/material';

import { Box } from 'styles/layout';

import FacilityInfo from './FacilityInfo';
import SystemNote from './SystemNote';
import FacilityNote from './FacilityNote';
import BstCoverage from './BstCoverage';
import CoverageModels from './CoverageModels';

import { MenuHeading } from '../ConsultMenu.style';

const ConsultInfo = ({ consultType, consultFacility }) => (
  <>
    <Box mb={3}>
      <MenuHeading>CPN Coverage</MenuHeading>
      <BstCoverage consultFacility={consultFacility} />
    </Box>
    <Box mb={3}>
      <MenuHeading>Coverage Models</MenuHeading>
      <CoverageModels consultFacility={consultFacility} />
    </Box>
    <Box mb={3}>
      <MenuHeading>Clinical Callouts</MenuHeading>
      <OutlinedInput
        value={consultFacility?.workflowDeviations ?? ''}
        fullWidth
        multiline
        disabled
        rows={3}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>Facility Info</MenuHeading>
      <FacilityInfo
        consultFacility={consultFacility}
        consultType={consultType}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>My System Notes</MenuHeading>
      <SystemNote
        consultType={consultType}
        healthSystemId={consultFacility?.healthSystem?.id}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>My Facility Notes</MenuHeading>
      <FacilityNote
        consultType={consultType}
        facilityId={consultFacility?.id}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>BSL System Notes</MenuHeading>
      <OutlinedInput
        value={consultFacility?.healthSystem?.note ?? ''}
        fullWidth
        multiline
        disabled
        rows={3}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>BSL Facility Notes</MenuHeading>
      <OutlinedInput
        value={consultFacility?.note ?? ''}
        fullWidth
        multiline
        disabled
        rows={3}
      />
    </Box>
  </>
);

ConsultInfo.propTypes = {
  consult: PropTypes.shape({}).isRequired,
  consultType: PropTypes.string.isRequired,
  consultFacility: PropTypes.shape({
    id: PropTypes.number,
    healthSystem: PropTypes.shape({
      id: PropTypes.number,
      note: PropTypes.string,
    }),
    note: PropTypes.string,
    workflowDeviations: PropTypes.string,
  }),
};

export default ConsultInfo;
