import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import api from 'api';
import {
  useMountEffect,
  useUpdateEffect,
  useFilterState,
  useFormState,
  useTableState,
  useRequest,
} from 'shared/hooks';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { formatDateToStr, formatIsoDate } from 'utils/dates';
import {
  ServiceTypeEnum,
  ServiceTypeLabels,
  NeuroConsultLabels,
  TeleNeuroConsultLabels,
  EegConsultLabels,
} from 'utils/enums';

import ConsultIntegrationDialog from 'features/consults/components/ConsultIntegration/ConsultIntegrationDialog';
import ConsultIntegrationMerge from 'features/consults/components/ConsultIntegration/ConsultIntegrationMerge';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { FlexBox } from 'styles/layout';

const tableColumns = [
  {
    header: {
      id: 'facilityName',
      value: 'Facility',
      isSortable: true,
    },
    dataKey: 'facilityName',
  },
  {
    header: {
      id: 'patientFirstName',
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'patientFirstName',
  },
  {
    header: {
      id: 'patientLastName',
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'patientLastName',
  },
  {
    header: {
      id: 'patientDateOfBirth',
      value: 'DOB',
      isSortable: true,
    },
    width: '10%',
    formatValue: ({ patientDateOfBirth }) => formatIsoDate(patientDateOfBirth),
  },
  {
    header: {
      id: 'type',
      value: 'Service Type',
      isSortable: true,
    },
    formatValue: ({ consultType }) => ServiceTypeLabels[consultType],
  },
  {
    header: {
      id: 'demographicConsultType',
      value: 'Consult Type',
      isSortable: true,
    },
    formatValue: ({ consultType, demographicConsultType }) => {
      switch (consultType) {
        case ServiceTypeEnum.NEURO:
          return NeuroConsultLabels[demographicConsultType];
        case ServiceTypeEnum.TELE_NEURO:
          return TeleNeuroConsultLabels[demographicConsultType];
        case ServiceTypeEnum.EEG:
        case ServiceTypeEnum.PEDS_EEG:
          return EegConsultLabels[demographicConsultType];
        default:
          return demographicConsultType;
      }
    },
  },
  {
    header: {
      id: 'createdAt',
      value: 'CreatedAt',
      isSortable: true,
    },
    width: '10%',
    formatValue: ({ createdAt }) => formatDateToStr(createdAt),
  },
];

const OrderMatch = ({ order, onMatch }) => {
  const [toMerge, setToMerge] = React.useState(null);
  const { register, setFormValues } = useFormState();
  const {
    page,
    setPage,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState('createdAt');
  const { filters, handleFilter } = useFilterState({ query: '' }, setPage);
  const { data: consultData, doRequest: getConsults } = useRequest(
    api.getOrderConsults,
  );

  const fetchConsultList = React.useCallback(() => {
    getConsults({
      page,
      sortBy,
      sortOrder,
      hl7OrderId: order.id,
      filters,
    });
  }, [getConsults, page, sortBy, sortOrder, order.id, filters]);

  useMountEffect(fetchConsultList);

  useUpdateEffect(() => {
    fetchConsultList();
  }, [fetchConsultList]);

  React.useEffect(() => {
    setFormValues(filters);
  }, [setFormValues, filters]);

  const openMergeModal = React.useCallback(
    (consultId) => {
      const clickedConsult = consultData?.consults.find(
        ({ id }) => id === consultId,
      );
      if (clickedConsult) setToMerge(clickedConsult);
    },
    [consultData],
  );

  if (!consultData) return <LoadingSpinner />;

  return (
    <>
      {!!toMerge && (
        <ConsultIntegrationDialog open onClose={() => setToMerge(null)}>
          <ConsultIntegrationMerge
            consult={{
              demographics: {
                firstName: toMerge.patientFirstName,
                lastName: toMerge.patientLastName,
                dateOfBirth: toMerge.patientDateOfBirth,
              },
            }}
            order={order}
            onMerge={() => {
              onMatch(toMerge.id);
              setToMerge(null);
            }}
            onClose={() => setToMerge(null)}
            closeStr="Back"
          />
        </ConsultIntegrationDialog>
      )}

      <Typography sx={{ fontSize: 15 }} variant="h6">
        Please select a consult to merge with:
      </Typography>
      <FlexBox mb={1}>
        <SearchField
          {...register('query')}
          onChange={(value) => handleFilter({ query: value })}
          fullWidth
        />
      </FlexBox>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(consultData?.consults || [], tableColumns)}
        fallbackMsg="No Consults found"
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={page}
        totalPages={consultData?.pageCount}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        onClickRow={openMergeModal}
      />
    </>
  );
};

OrderMatch.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onMatch: PropTypes.func.isRequired,
};

export default OrderMatch;
