import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useConsult } from 'shared/context/ConsultContext';
import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import ConsultLock from 'features/consults/components/ConsultLock/ConsultLock';
import ConsultIcd from 'features/consults/components/ConsultIcd';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import Autocomplete from 'shared/components/forms/Autocomplete';
import QualityReviewPanel from 'features/consults/shared/tabs/Signature/QualityReviewPanel';
import { teleNeuroSignatureFields as formFields } from 'features/consults/shared/tabs/Signature/formFields';
import { neuroSignatureSchema } from 'features/consults/shared/tabs/Signature/validators';
import { neuroIcds } from 'features/consults/shared/tabs/Signature/data';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { Box } from 'styles/layout';

import { teleNeuroSignatureRequiredFields } from 'features/consults/utils/validators';
import leftPanel from './data';

const SignatureTeleNeuro = ({ errors }) => {
  const {
    register,
    control,
    getValues,
    setValue,
    getFieldError,
    setFormValues,
    clearErrors,
    trigger,
    watch,
  } = useFormState(neuroSignatureSchema, {
    stateSlice: 'consults',
    showAsSnackbar: true,
  });
  const {
    consult,
    consultFacility,
    consultTabData,
    isClosed,
    isEditable,
    isMacro,
    handleFieldChange,
    handleOwnerChange,
    handleConsultLock,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.SIGNATURE, getValues);

  const { consultProviders } = useConsult();

  const currentAttestationValue = watch(
    formFields.attestationOfConsultCompletion.name,
  );

  const requiredFields = React.useMemo(
    () => teleNeuroSignatureRequiredFields(consult, consultFacility),
    [consult, consultFacility]
  );

  const updatedLeftPanel = React.useMemo(() => ({
    ...leftPanel,
    elements: leftPanel.elements.map((field) => ({
      ...field,
      required: requiredFields.some((reqField) => reqField.name === field.name),
    })),
  }), [requiredFields]);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  React.useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  return (
    <GridWrapper>
      <Grid item sm={12} lg={6}>
        <QuestionsPanel
          {...updatedLeftPanel}
          control={control}
          register={register}
          onChange={handleFieldChange}
          disabled={!isEditable}
          getFieldError={getFieldError}
          disableElements={
            isMacro
              ? [
                  formFields.attestationOfConsultCompletion.name,
                  formFields.consent.name,
                  formFields.totalFaceToFaceTime.name,
                  formFields.criticalCareTime.name,
                  formFields.billingCode.name,
                  formFields.logistics.name,
                ]
              : []
          }
        />
        <Autocomplete
          name="attendingPhysicianId"
          label="Attending Physician"
          control={control}
          options={consultProviders}
          disabled={isMacro || !isEditable}
          handleChange={() => handleFieldChange('attendingPhysicianId')}
        />
        <Box mt={1}>
          <ConsultIcd
            consultId={consult.id}
            consultData={consultTabData}
            disabled={!isEditable}
            onSetValue={setValue}
            getFieldError={getFieldError}
            required={requiredFields.includes(formFields.icdCodes)}
            isMacro={isMacro}
            defaultOptions={neuroIcds}
          />
        </Box>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Box mb={1}>
          <Autocomplete
            control={control}
            name="assignToId"
            label="Neurology Provider"
            options={consultProviders}
            required
            disabled={isMacro || isClosed}
            handleChange={handleOwnerChange}
          />
        </Box>
        {!isMacro && (
          <ConsultLock
            attestationValue={currentAttestationValue}
            disabled={!isEditable}
            checked={isClosed}
            onConsultLock={handleConsultLock}
          />
        )}
        <QualityReviewPanel
          form={{
            control,
            register,
            watch,
            getValues,
            setFormValues,
            getFieldError,
          }}
          withOptions
          onChange={saveConsultChange}
          onFieldChange={handleFieldChange}
          disabled={isMacro || !isEditable}
        />
      </Grid>
    </GridWrapper>
  );
};

SignatureTeleNeuro.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default SignatureTeleNeuro;
