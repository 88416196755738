import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import styled from '@emotion/styled';

import ChipList from 'shared/components/forms/Chip/ChipList';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import InputField from 'shared/components/forms/InputField/InputField';
import Autocomplete from 'shared/components/forms/Autocomplete';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import ConsultRequestTypeSelect from 'features/customer/components/ConsultRequest/ConsultRequestTypeSelect';
import { Box } from 'styles/layout';

import { ServiceTypeEnum, NeuroConsultTypes } from 'utils/enums';
import {
  videoOptions,
  PatientLocations,
} from 'features/consults/shared/tabs/Demographics/formFields';

import {
  genderOptions,
  patientLocationOptions,
  eegConsultOptions,
  statOptions,
  teleneuroConsultOptions,
  formFields,
  teleNeuroLabel,
} from './data';

const FlexGridColumn = styled(Grid)`
  flex-direction: column;
`;

const ConsultRequestForm = ({
  formProps: { control, register, setValue, getFieldError, watch },
  facilities = [],
  typeOptions = [],
  readOnly,
}) => {
  const selectedConsultType = watch(formFields.consultType);
  const patientLocation = watch(formFields.patientLocation);
  const selectedFacility = watch(formFields.facility);

  const isEeg = React.useMemo(
    () =>
      [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(
        selectedConsultType,
      ),
    [selectedConsultType],
  );

  const directBeamInOptions = React.useMemo(() => {
    if (!selectedFacility) return { display: false, defaultSelected: false };
    const facility = facilities.find((item) => item.id === selectedFacility);

    if (!facility?.directBeamIn) {
      return { display: false, defaultSelected: false };
    }

    if (selectedConsultType === NeuroConsultTypes.ACUTE_LEVEL1) {
      if (patientLocation === PatientLocations.EmergencyRoom) {
        return { display: true, defaultSelected: true };
      }
      if (patientLocation === PatientLocations.Inpatient) {
        return { display: true, defaultSelected: false };
      }
    }
    return { display: false, defaultSelected: false };
  }, [selectedFacility, facilities, selectedConsultType, patientLocation]);

  React.useEffect(() => {
    if (directBeamInOptions.defaultSelected) {
      setValue(formFields.neuroDirectBeamInRequested, ['directBeamIn']);
    } else {
      setValue(formFields.neuroDirectBeamInRequested, []);
    }
  }, [directBeamInOptions.defaultSelected, setValue]);

  return (
    <GridWrapper spacing={10}>
      <FlexGridColumn container item sm={12} lg={5} rowSpacing={2}>
        <Grid item>
          <InputField
            {...register(formFields.firstName, {
              required: true,
              disabled: readOnly,
            })}
            label="First Name"
            required
            error={getFieldError(formFields.firstName)}
          />
        </Grid>
        <Grid item>
          <InputField
            {...register(formFields.lastName, {
              required: true,
              disabled: readOnly,
            })}
            label="Last Name"
            required
            error={getFieldError(formFields.lastName)}
          />
        </Grid>
        <Grid item>
          <DatePicker
            control={control}
            name={formFields.dateOfBirth}
            label="Date of Birth"
            error={getFieldError(formFields.dateOfBirth)}
            openTo="year"
            disableFuture
            disabled={readOnly}
          />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            control={control}
            name={formFields.gender}
            title="Gender"
            compact
            buttons={genderOptions}
            disabled={readOnly}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            control={control}
            name={formFields.facility}
            label="Facility"
            options={facilities}
            required
            groupBy={({ healthSystemName }) => healthSystemName}
            disabled={readOnly || facilities.length < 2}
            error={getFieldError(formFields.facility)}
          />
        </Grid>
        <Grid item>
          <ConsultRequestTypeSelect
            control={control}
            name={formFields.consultType}
            options={typeOptions}
            handleChange={() => {
              setValue(formFields.eegReadConsultType, null);
              setValue(formFields.neuroHospitalConsultType, null);
            }}
            error={getFieldError(formFields.consultType)}
            disabled={readOnly}
          />
        </Grid>

        {selectedConsultType === ServiceTypeEnum.TELE_NEURO && (
          <Grid item>
            <ToggleButtonGroup
              control={control}
              name={formFields.neuroHospitalConsultType}
              title={teleNeuroLabel}
              compact
              required
              buttons={teleneuroConsultOptions}
              error={getFieldError(formFields.neuroHospitalConsultType)}
              disabled={readOnly}
            />
          </Grid>
        )}

        {isEeg && (
          <Grid item>
            <Box mb={1}>
              <ToggleButtonGroup
                control={control}
                name={formFields.eegReadConsultType}
                title="Type of EEG Read"
                compact
                required
                buttons={eegConsultOptions(selectedConsultType)}
                error={getFieldError(formFields.eegReadConsultType)}
                disabled={readOnly}
              />
            </Box>
            <ToggleButtonGroup
              control={control}
              name={formFields.videoType}
              compact
              required
              buttons={videoOptions.map((v) => ({
                ...v,
                content: v.label,
              }))}
              error={getFieldError(formFields.videoType)}
              disabled={readOnly}
            />
          </Grid>
        )}

        <Grid item>
          <ToggleButtonGroup
            control={control}
            name={formFields.patientLocation}
            title="Patient Location"
            compact
            required
            buttons={patientLocationOptions(selectedConsultType)}
            error={getFieldError(formFields.patientLocation)}
            disabled={readOnly}
          />
        </Grid>
      </FlexGridColumn>
      <FlexGridColumn container item sm={12} lg={5} rowSpacing={2}>
        <Grid item>
          <InputField
            {...register(formFields.provider, {
              disabled: readOnly,
            })}
            label="Provider Requesting Consult"
            error={getFieldError(formFields.provider)}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={8}>
            <PhoneNumberInput
              control={control}
              name={formFields.phoneNumber}
              label="Phone"
              required
              error={getFieldError(formFields.phoneNumber)}
              disabled={readOnly}
              renderLinkLabel={false}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              {...register(formFields.phoneNumberExtension, {
                disabled: readOnly,
              })}
              label="Extension"
              type="number"
              error={getFieldError(formFields.phoneNumberExtension)}
              disabled={readOnly}
            />
          </Grid>
        </Grid>

        {isEeg && (
          <>
            <Grid item>
              <InputField
                {...register(formFields.eegTechName, {
                  disabled: readOnly,
                })}
                label="Referring Technician"
                error={getFieldError(formFields.eegTechName)}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={8}>
                <PhoneNumberInput
                  control={control}
                  name={formFields.eegTechCallBackNumber}
                  label="Tech Callback Number"
                  required
                  error={getFieldError(formFields.eegTechCallBackNumber)}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...register(formFields.eegTechCallBackNumberExtension, {
                    disabled: readOnly,
                  })}
                  label="Extension"
                  type="number"
                  error={getFieldError(
                    formFields.eegTechCallBackNumberExtension,
                  )}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
            <Grid item>
              <ChipList
                control={control}
                name={formFields.statReadRequested}
                options={[
                  {
                    value: formFields.statReadRequested,
                    text: 'STAT Read Requested',
                  },
                ]}
                disabled={readOnly}
              />
            </Grid>
          </>
        )}

        {!isEeg && (
          <>
            <Grid item>
              <InputField
                {...register(formFields.referringRN, {
                  disabled: readOnly,
                })}
                label="Referring RN"
                error={getFieldError(formFields.referringRN)}
                disabled={readOnly}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={8}>
                <PhoneNumberInput
                  control={control}
                  name={formFields.rnCallbackNumber}
                  label="RN Callback Number"
                  error={getFieldError(formFields.rnCallbackNumber)}
                  disabled={readOnly}
                  renderLinkLabel={false}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...register(formFields.rnCallbackNumberExtension, {
                    disabled: readOnly,
                  })}
                  label="Extension"
                  type="number"
                  error={getFieldError(
                    formFields.rnCallbackNumberExtension,
                  )}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
            <Grid item>
              <InputField
                {...register(formFields.neuroCameraName, {
                  disabled: readOnly,
                })}
                label="Camera Name"
                error={getFieldError(formFields.neuroCameraName)}
                disabled={readOnly}
              />
            </Grid>
          </>
        )}

        {directBeamInOptions.display && (
          <Grid item>
            <ChipList
              control={control}
              name={formFields.neuroDirectBeamInRequested}
              options={[
                {
                  value: 'directBeamIn',
                  text: 'Direct Beam-in Requested',
                },
              ]}
              disabled={readOnly}
            />
          </Grid>
        )}

        {selectedConsultType === NeuroConsultTypes.GENERAL &&
          patientLocation === PatientLocations.Inpatient && (
            <Grid item>
              <ChipList
                control={control}
                name="stat"
                options={statOptions}
                disabled={readOnly}
              />
            </Grid>
          )}

        <Grid item>
          <InputField
            {...register(formFields.notes, {
              disabled: readOnly,
            })}
            label="Notes"
            error={getFieldError(formFields.notes)}
            multiline
            rows={8}
          />
        </Grid>
      </FlexGridColumn>
    </GridWrapper>
  );
};

ConsultRequestForm.propTypes = {
  formProps: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.shape({}).isRequired,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
  }).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  typeOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ConsultRequestForm;
