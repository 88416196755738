import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  useMountEffect,
  useUpdateEffect,
  useInterval,
  useFormState,
  useAssets,
  usePerformActionOnSuccess,
} from 'shared/hooks';
import ConsultRequestFilters from 'features/customer/components/ConsultRequestFilters/ConsultRequestFilters';
import ConsultRequestList from 'features/customer/components/ConsultRequestList/ConsultRequestList';
import useConsultRequestListState from 'features/customer/hooks/useConsultRequestListState';
import { consultRequestActions } from 'features/customer/store/slice';
import { ConsultRequestStatusEnum } from 'utils/enums';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { FlexBoxColumn } from 'styles/layout';

const stateFilterMap = {
  [ConsultRequestStatusEnum.OPEN]: [
    ConsultRequestStatusEnum.OPEN,
    ConsultRequestStatusEnum.ACCEPTED,
  ],
  [ConsultRequestStatusEnum.CLOSED]: [ConsultRequestStatusEnum.CLOSED],
};

const ConsultRequestListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { assets, fetchAssets } = useAssets();
  const { data, pageCount } = useSelector(
    ({ consultRequests }) => consultRequests,
  );
  const {
    page,
    filters,
    handlePageChange,
    handleFilterChange,
    resetFilters,
    sortBy,
    sortOrder,
    setQueryParams,
  } = useConsultRequestListState();

  useFormState(
    {},
    {
      stateSlice: 'consultRequests',
      showAsSnackbar: true,
    },
  );

  const fetchConsultRequestList = useCallback(
    (isAutoRefresh) => {
      const { state, ...moreFilters } = filters;
      dispatch(
        consultRequestActions.listConsultRequests({
          page,
          sortBy,
          sortOrder,
          filters: {
            ...moreFilters,
            states: stateFilterMap[state],
            isAutoRefresh,
          },
        }),
      );
      if (!isAutoRefresh) {
        setQueryParams({
          ...filters,
          page,
          sortBy,
          sortOrder,
        });
      }
    },
    [filters, page, sortBy, sortOrder, dispatch, setQueryParams],
  );

  useMountEffect(fetchConsultRequestList);

  useUpdateEffect(() => {
    fetchConsultRequestList();
  }, [filters, page]);

  useUpdateEffect(() => {
    if (!data.length) return;
    const ids = data.reduce(
      (res, { providerId }) =>
        !providerId || res.includes(String(providerId))
          ? res
          : [...res, String(providerId)],
      [],
    );
    if (ids.every((id) => !!assets[id])) return;
    fetchAssets(ids);
  }, [data]);

  // Automatically fetch the consult request data every minute
  useInterval(() => {
    fetchConsultRequestList(true);
  }, 60 * 1000);

  // Updates list on deletion
  usePerformActionOnSuccess(
    SuccessActionTypesEnum.CONSULT_REQUEST_DELETED,
    fetchConsultRequestList,
  );

  return (
    <FlexBoxColumn>
      <ConsultRequestFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onResetFilters={resetFilters}
        onCreateNewConsultRequest={() => navigate('new-consult-request')}
      />
      <FlexBoxColumn>
        <ConsultRequestList
          items={data}
          page={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default ConsultRequestListPage;
