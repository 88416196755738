import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'api';
import {
  useFilterState,
  useMountEffect,
  useUpdateEffect,
  useProfile,
  useFeedback,
  useQueryParams,
} from 'shared/hooks';

import {
  LAST_CALENDAR_DAY_INDEX,
  ScheduleViewEnum,
} from 'features/schedule/utils/constants';
import { scheduleActions } from 'features/schedule/store/slice';
import {
  TIME_FORMAT,
  addDays,
  eachDayOfInterval,
  formatDate,
  formatToBrowserDate,
  isSameDay,
  isToday,
  parseISO,
  startOfWeek,
} from 'utils/dates';
import { ServiceTypeEnum, ConsultNetworkTypesEnum } from 'utils/enums';
import { downloadFile } from 'utils/helpers';

import colors from 'styles/colors';

const shiftColors = {
  [ConsultNetworkTypesEnum[ServiceTypeEnum.NEURO]]: colors.shiftNeuroBackground,
  [ConsultNetworkTypesEnum[ServiceTypeEnum.EEG]]: colors.shiftEegBackground,
  [ConsultNetworkTypesEnum[ServiceTypeEnum.TELE_NEURO]]:
    colors.shiftTeleNeuroBackground,
  [ConsultNetworkTypesEnum[ServiceTypeEnum.PEDS_EEG]]:
    colors.shiftPedoEegBackground,
};

const getScheduleFilters = (filters, profileData) => {
  const { scheduleView, userId, ...restFilters } = filters;
  return {
    ...restFilters,
    userId: scheduleView === ScheduleViewEnum.MY ? profileData.id : userId,
  };
};

const useCalendarData = () => {
  const dispatch = useDispatch();
  const { setQueryParams } = useQueryParams();
  const { shifts } = useSelector(({ schedule }) => schedule);
  const { isDoctor, profileData } = useProfile();
  const { clearDisplay, errorDisplay } = useFeedback();

  const { filters, handleFilter } = useFilterState({
    scheduleView: isDoctor ? ScheduleViewEnum.MY : ScheduleViewEnum.ALL,
    facilityId: null,
    networks: null,
    networkType: null,
    userId: null,
  });

  const { calendarStartDate, calendarEndDate } = useMemo(() => {
    const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 }); // Week starts on Monday
    const lastCalendarDay = addDays(firstDayOfWeek, LAST_CALENDAR_DAY_INDEX);

    return {
      calendarStartDate: formatToBrowserDate(firstDayOfWeek),
      calendarEndDate: formatToBrowserDate(lastCalendarDay),
    };
  }, []);

  const fetchShiftList = useCallback(() => {
    if (
      (filters.scheduleView === ScheduleViewEnum.MY && profileData.id) ||
      filters.scheduleView === ScheduleViewEnum.ALL
    ) {
      dispatch(
        scheduleActions.listScheduleShifts({
          startDate: calendarStartDate,
          endDate: calendarEndDate,
          filters: getScheduleFilters(filters, profileData),
          errCb: () => errorDisplay('Error loading shifts'),
        }),
      );
      setQueryParams(filters);
    }
  }, [
    calendarStartDate,
    calendarEndDate,
    dispatch,
    errorDisplay,
    filters,
    profileData,
    setQueryParams,
  ]);

  useMountEffect(fetchShiftList);

  useUpdateEffect(() => {
    fetchShiftList();
  }, [filters]);

  const calendarDays = useMemo(() => {
    const mappedShifts = shifts.map((shift) => {
      const {
        networkType,
        shiftAdminFacilityName,
        shiftAdminFacilityShortName,
        shiftAdminUserFirstName,
        shiftAdminUserLastName,
        shiftStart,
        shiftEnd,
      } = shift;
      const shiftStartDate = parseISO(shiftStart);
      const shiftEndDate = parseISO(shiftEnd);

      return {
        ...shift,
        network: shiftAdminFacilityShortName || shiftAdminFacilityName,
        provider: `${shiftAdminUserFirstName} ${shiftAdminUserLastName}`,
        shiftStart: shiftStartDate,
        shiftEnd: shiftEndDate,
        startTime: formatDate(shiftStartDate, TIME_FORMAT),
        endTime: formatDate(shiftEndDate, TIME_FORMAT),
        color: shiftColors[networkType],
      };
    });

    const timeInterval = eachDayOfInterval({
      start: parseISO(calendarStartDate),
      end: parseISO(calendarEndDate),
    });

    return timeInterval.map((date) => {
      const today = isToday(date);

      const formattedDate = today
        ? `Today - ${formatDate(date, 'MMM d')}`
        : formatDate(date, 'E MMM d');

      return {
        title: formattedDate,
        today,
        shifts: mappedShifts.filter((shift) =>
          isSameDay(date, shift.shiftStart),
        ),
      };
    });
  }, [shifts, calendarStartDate, calendarEndDate]);

  const hasShifts = useMemo(
    () => calendarDays.reduce((acc, day) => acc + day.shifts.length, 0) > 0,
    [calendarDays],
  );

  const handleExport = async (fileType) => {
    const exportFilters = {
      ...getScheduleFilters(filters, profileData),
      timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      clearDisplay();

      const { data, headers } = await api.exportShifts(
        calendarStartDate,
        calendarEndDate,
        exportFilters,
        fileType,
      );

      downloadFile(data, headers, fileType);
    } catch {
      errorDisplay(
        'An error occurred while downloading the file. Please, try again.',
      );
    }
  };

  return {
    calendarDays,
    hasShifts,
    filters,
    handleFilter,
    handleExport,
  };
};

export default useCalendarData;
