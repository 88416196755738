import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import sortFields from 'api/sortFields';
import {
  useUpdateEffect,
  useMountEffect,
  useFilterState,
  useTableState,
  useQueryParams,
} from 'shared/hooks';
import { consultsActions } from 'features/consults/store/slice';
import { ServiceShortEnum, ConsultStates } from 'utils/enums';
import { mapConsultFilters } from 'features/consults/utils/mappers';

import useConsultNavigation from './useConsultNavigation';

const useConsultsListState = (consultType) => {
  const dispatch = useDispatch();
  const { navigateToConsult } = useConsultNavigation(consultType);

  const { data: consults, pageCount } = useSelector(
    (state) => state.consults[ServiceShortEnum[consultType]],
  );
  const userId = useSelector(({ profile }) => profile.data.id);
  const { currentConsult } = useSelector((state) => state.consults);

  const consultState = useMemo(
    () => currentConsult?.signature?.state,
    [currentConsult?.signature?.state],
  );

  const consultAssignee = useMemo(
    () => currentConsult?.signature?.assignToId,
    [currentConsult?.signature?.assignToId],
  );

  const { queryParams, setQueryParams } = useQueryParams();
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
  } = useTableState(sortFields.consults.createdAt);

  const { filters, handleFilter, handleResetFilters } = useFilterState(
    {
      query: '',
      assignToUserIds: queryParams.page ? '' : String(userId),
      state: queryParams.page ? '' : ConsultStates.OPEN,
      fromCreatedAt: null,
      toCreatedAt: null,
      networkId: null,
      healthcareSystemId: null,
      facilityId: null,
    },
    setPage,
    handleSortChange,
  );

  const handleRefresh = useCallback(() => {
    if (consultType) {
      const mappedFilters = mapConsultFilters(filters);
      dispatch(
        consultsActions.listConsults({
          consultType: ServiceShortEnum[consultType],
          page,
          sortBy,
          sortOrder,
          filters: mappedFilters,
        }),
      );

      setQueryParams({
        ...mappedFilters,
        page,
        sortBy,
        sortOrder,
      });
    }
  }, [consultType, dispatch, page, sortBy, sortOrder, filters, setQueryParams]);

  useMountEffect(handleRefresh);

  useUpdateEffect(() => {
    handleRefresh();
  }, [
    page,
    sortBy,
    sortOrder,
    filters,
    consultState,
    consultAssignee,
    currentConsult.id,
  ]);

  return {
    consults,
    page,
    pageCount,
    sortBy,
    sortOrder,
    filters,
    handleResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    handleRefresh,
    navigateToConsult,
  };
};

export default useConsultsListState;
