import React from 'react';
import { Link } from 'react-router-dom';

import ConsultIcon from '@mui/icons-material/ZoomIn';

import api from 'api';
import {
  useUpdateEffect,
  useMountEffect,
  useRequest,
  useProfile,
  useFilterState,
  useTableState,
} from 'shared/hooks';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import Paths from 'navigation/paths';
import AdminPaths from 'features/admin/paths';
import { FlexBoxColumn } from 'styles/layout';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import Button from 'shared/components/buttons/Button/Button';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import { formatToUserTimezone } from 'utils/dates';
import { ServiceTypeLabels } from 'utils/enums';

import {
  ConsultRequestListFilters,
  filterFields,
} from './ConsultRequestListFilters';

const getTableColumns = (viewConsultTable) => [
  {
    header: {
      id: 'FacilityName',
      value: 'Facility',
      isSortable: true,
    },
    dataKey: 'facilityName',
  },
  {
    header: {
      id: 'ServiceType',
      value: 'Service',
      isSortable: true,
    },
    formatValue: ({ serviceType }) => ServiceTypeLabels[serviceType],
  },
  {
    header: {
      id: 'CreatedByFullName',
      value: 'Created By',
      isSortable: true,
    },
    formatValue: ({ createdByFirstName, createdByLastName }) =>
      `${createdByFirstName} ${createdByLastName}`,
  },
  {
    header: {
      id: 'CreatedAt',
      value: 'Created At',
      isSortable: true,
    },
    formatValue: ({ createdAt }) => formatToUserTimezone(createdAt),
  },
  {
    header: {
      id: 'AcceptedAt',
      value: 'Accepted At',
      isSortable: true,
    },
    dataKey: 'acceptedAt',
    formatValue: ({ acceptedAt }) => formatToUserTimezone(acceptedAt),
  },
  {
    header: {
      id: 'ProviderFullName',
      value: 'Provider',
      isSortable: true,
    },
    formatValue: ({ providerFirstName, providerLastName }) =>
      `${providerFirstName || ''} ${providerLastName || ''}`,
  },
  {
    header: {
      id: 'ConsultRequestState',
      value: 'Consult',
      isSortable: true,
    },
    align: 'center',
    width: '100px',
    formatValue: ({ consultId }) =>
      consultId ? (
        <Button
          disabled={!viewConsultTable}
          component={Link}
          to={`/${Paths.Admin}/${AdminPaths.Consults}/${consultId}`}
        >
          {consultId}
        </Button>
      ) : (
        '-'
      ),
  },
  {
    header: {
      id: 'consult_url',
      value: '',
    },
    align: 'center',
    width: '80px',
    formatValue: ({ id }) => (
      <IconButton component={Link} to={id}>
        <ConsultIcon />
      </IconButton>
    ),
  },
];

const ConsultRequestList = () => {
  const { profileData } = useProfile();
  const {
    doRequest: getList,
    data: listData,
    isLoading,
  } = useRequest(api.getAdminConsultRequests);

  const {
    page,
    sortBy,
    sortOrder,
    setPage,
    handlePageChange,
    handleSortChange,
    setQueryParams,
  } = useTableState('CreatedAt', 'desc');
  const { filters, handleFilter } = useFilterState(
    Object.values(filterFields).reduce(
      (res, { name }) => ({ ...res, [name]: null }),
      {},
    ),
    setPage,
  );

  const fetchList = React.useCallback(() => {
    if (isLoading) return;
    getList({
      page,
      sortOrder,
      sortBy,
      filters,
    });
    setQueryParams({
      ...filters,
      page,
      sortBy,
      sortOrder,
    });
  }, [getList, page, sortOrder, sortBy, filters, isLoading, setQueryParams]);

  const handleApplyFilter = React.useCallback(() => {
    fetchList();
  }, [fetchList]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [page, sortOrder, sortBy]);

  const tableColumns = React.useMemo(
    () => getTableColumns(profileData.viewAdminConsultTable),
    [profileData.viewAdminConsultTable],
  );

  return (
    <FlexBoxColumn>
      <PageTitle>Consult Requests</PageTitle>
      <ConsultRequestListFilters
        filters={filters}
        handleFilter={handleFilter}
        handleApplyFilter={handleApplyFilter}
        loading={isLoading}
      />
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(listData?.consults, tableColumns)}
        fallbackMsg="No consult requests found"
        loading={isLoading}
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={page}
        totalPages={listData?.pageCount}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
      />
    </FlexBoxColumn>
  );
};

export default ConsultRequestList;
