import {
  ServiceTypeEnum,
  NeuroConsultLabels,
  TeleNeuroConsultLabels,
  EegConsultLabels,
  PedsEegConsultLabels,
} from 'utils/enums';


export const filterTypes = {
  DATETIME: 'datetime',
  SELECT: 'select',
  PROVIDER: 'provider',
  HEALTHSYSTEM: 'healthSystem',
  FACILITY: 'facility',
  NETWORK: 'network',
  MULTISELECT: 'multiselect',
};

const neuroConsultTypeFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'neuroConsultTypes',
  placeholder: 'Consult Type',
  options: Object.entries(NeuroConsultLabels).map(([id, name]) => ({
    id,
    name,
  })),
};

const teleNeuroTypeFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'teleNeuroHospitalConsultTypes',
  placeholder: 'Consult Type',
  options: Object.entries(TeleNeuroConsultLabels).map(([id, name]) => ({
    id,
    name,
  })),
};

const eEGTypeFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'eegConsultTypes',
  placeholder: 'Consult Type',
  options: Object.entries(EegConsultLabels).map(([id, name]) => ({
    id,
    name,
  })),
};

const pedsEEGTypeFilter = {
  filterType: filterTypes.SELECT,
  name: 'pedsEegConsultTypes',
  placeholder: 'Consult Type',
  options: Object.entries(PedsEegConsultLabels).map(([id, name]) => ({
    id,
    name,
  })),
};

const filterData = {
  [ServiceTypeEnum.NEURO]: [neuroConsultTypeFilter],
  [ServiceTypeEnum.TELE_NEURO]: [teleNeuroTypeFilter],
  [ServiceTypeEnum.EEG]: [eEGTypeFilter],
  [ServiceTypeEnum.PEDS_EEG]: [pedsEEGTypeFilter],
};

export const serviceFilters = (service) => filterData[service];
