import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { FlexBoxSpaceBetweenCenter } from 'styles/layout';
import colors from 'styles/colors';
import { ServiceTypeLabels } from 'utils/enums';

const FacilityInfoLabel = styled.span`
  color: ${colors.neutralDark};
  font-weight: 500;
  font-size: 12px;
`;

const FacilityInfoValue = styled.span`
  color: ${colors.neutralDark};
  font-size: 12px;
`;

const BstCoverage = ({ consultFacility }) =>
  consultFacility?.networks?.map(({ network, networkType }) => (
    <FlexBoxSpaceBetweenCenter key={networkType} mb={1}>
      <FacilityInfoLabel>{networkType ? ServiceTypeLabels[networkType] : ''} Network</FacilityInfoLabel>
      <FacilityInfoValue>{network?.name ?? ''}</FacilityInfoValue>
    </FlexBoxSpaceBetweenCenter>
  ));

BstCoverage.propTypes = {
  consultFacility: PropTypes.shape({}),
};

export default BstCoverage;
