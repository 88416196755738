import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { consultsActions } from 'features/consults/store/slice';
import { useConsult } from 'shared/context/ConsultContext';
import { differenceInYears, formatToBrowserDate } from 'utils/dates';
import { capitalizeFirstLetter } from 'utils/helpers';

const useSharedHandlers = (
  consultId,
  consultMainType,
  saveConsult,
  setFormValue,
  formFields,
) => {
  const dispatch = useDispatch();
  const { consultFacilities: facilities } = useConsult();

  const handleNameChange = (fieldName) => {
    const value = formFields(fieldName);

    if (value !== undefined) {
      const formattedValue = value.trim();
      const propName = `patient${capitalizeFirstLetter(fieldName)}`;

      dispatch(
        consultsActions.updateConsultInList({
          id: consultId,
          consultType: consultMainType,
          propToUpdate: { [propName]: formattedValue },
        }),
      );
      saveConsult({
        [fieldName]: formattedValue,
      });
    }
  };

  const handleFacilityChange = useCallback(
    (facilityId) => {
      const facilityName = facilityId
        ? facilities?.find(({ id }) => id === facilityId)?.name?.split(' - ')[0]
        : null;

      dispatch(
        consultsActions.updateConsultInList({
          id: consultId,
          consultType: consultMainType,
          propToUpdate: { facilityName },
        }),
      );
    },
    [facilities, consultId, consultMainType, dispatch],
  );

  const handleDateOfBirthChange = (dob) => {
    if (!dob) {
      setFormValue('age', null);
      saveConsult({
        dateOfBirth: null,
        age: null,
      });
      return;
    }

    const patientAge = differenceInYears(new Date(), dob);

    setFormValue('age', patientAge);
    saveConsult({
      dateOfBirth: formatToBrowserDate(dob),
      age: patientAge,
    });
  };

  return {
    facilities,
    handleNameChange,
    handleFacilityChange,
    handleDateOfBirthChange,
  };
};

export default useSharedHandlers;
