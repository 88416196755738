import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import sortFields from 'api/sortFields';
import {
  useFilterState,
  useTableState,
  useMountEffect,
  useUpdateEffect,
} from 'shared/hooks';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import IntegrationIcon from 'shared/components/icons/IntegrationIcon';
import { adminActions } from 'features/admin/store/slice';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { ServiceTypeEnum, ServiceTypeLabels } from 'utils/enums';
import {
  US_DATE_FORMAT,
  TIME_FORMAT,
  formatToUserTimezone,
  startOfMonthStr,
} from 'utils/dates';
import { EMPTY_COLUMN_VALUE } from 'utils/constants';

const tableColumns = (service) => [
  {
    header: {
      id: sortFields.consults.facilityName,
      value: 'Facility',
      isSortable: true,
    },
    dataKey: 'facilityName',
    width: '35%',
  },
  {
    header: {
      id: sortFields.consults.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'patientFirstName',
  },
  {
    header: {
      id: sortFields.consults.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'patientLastName',
  },
  {
    header: {
      id: sortFields.consults.type,
      value: 'Service',
      isSortable: !service,
    },
    formatValue: ({ consultType }) => ServiceTypeLabels[consultType],
  },
  {
    header: {
      id: sortFields.consults.assignTo,
      value: 'Provider',
      isSortable: true,
    },
    formatValue: (item) =>
      item.assignToFirstName
        ? `${item.assignToFirstName} ${item.assignToLastName}`
        : EMPTY_COLUMN_VALUE,
  },
  {
    header: {
      id: sortFields.consults.createdAt,
      value: 'Created At',
      isSortable: true,
    },
    width: '20%',
    formatValue: (item) =>
      formatToUserTimezone(
        new Date(item.createdAt),
        `${US_DATE_FORMAT} - ${TIME_FORMAT}`,
      ),
  },
  {
    header: {
      id: 'integration',
      value: '',
    },
    width: '3.5rem',
    formatValue: ({ integrationStatus, consultType }) => {
      if (consultType !== ServiceTypeEnum.NEURO) return null;
      return <IntegrationIcon integrationStatus={integrationStatus} />;
    },
  },
];

const defaultFilterValues = {
  // shared filters
  service: '',
  query: null,
  fromCreatedAt: null,
  toCreatedAt: null,
  assignToUserIds: null,
  state: null,

  // Neuro & EEG
  networkId: null,
  healthcareSystemId: null,
  facilityId: null,

  // Neuro
  neuroConsultTypes: [],
  patientLocationType: '',
  withQualityFlagSelected: '',
  neuroAttestationOfConsultCompletionTypes: [],
  planThrombolyticInterventionTypes: [],
  integrationStatusList: '',

  // EEG
  eegConsultType: '',
  eegReadConsultObservationType: '',
  eegAttestationOfEegCompletionTypes: [],
  withEegStatOptionsTypeSelected: '',
};

const useAdminConsultsListState = () => {
  const dispatch = useDispatch();
  const { setQueryParams } = useQueryParams();

  const [loading, setLoading] = React.useState(false);

  const {
    data: consults,
    pageCount,
    totalCount,
  } = useSelector(({ admin }) => admin.consults);
  const {
    page,
    setPage,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState(sortFields.consults.createdAt, 'desc');
  const { filters, handleFilter, handleResetFilters } = useFilterState(
    defaultFilterValues,
    setPage,
  );

  const onResetFilters = React.useCallback(
    ({ service }) =>
      handleResetFilters({
        ...defaultFilterValues,
        service,
        fromCreatedAt: service ? startOfMonthStr() : null,
      }),
    [handleResetFilters],
  );

  const fetchList = React.useCallback(() => {
    if (loading) return;
    const { service, ...moreFilters } = filters;
    setLoading(true);
    dispatch(
      adminActions.listConsults({
        service,
        filters: moreFilters,
        page,
        sortBy,
        sortOrder,
      }),
    )
      .then(() => {
        setQueryParams({ ...filters, page, sortBy, sortOrder });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setQueryParams, loading, page, sortBy, sortOrder, filters]);

  const handleApplyFilter = React.useCallback(() => {
    fetchList();
  }, [fetchList]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [page, sortBy, sortOrder]);

  const serviceColumns = React.useMemo(
    () => tableColumns(filters.service),
    [filters.service],
  );

  const tableHeaders = React.useMemo(
    () => getTableHeaders(serviceColumns),
    [serviceColumns],
  );

  const tableData = React.useMemo(
    () => prepareTableData(consults, serviceColumns),
    [serviceColumns, consults],
  );

  return {
    page,
    pageCount,
    totalCount,
    sortBy,
    sortOrder,
    filters,
    loading,
    handleResetFilters: onResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    handleApplyFilter,
    tableHeaders,
    tableData,
  };
};

export default useAdminConsultsListState;
