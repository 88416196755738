import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';
import { DateTimeFilter } from 'shared/components/forms/DateTimerPicker';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import Autocomplete from 'shared/components/forms/Autocomplete';
import Button from 'shared/components/buttons/Button/Button';
import { ServiceTypeLabels } from 'utils/enums';
import { FlexBoxAlign } from 'styles/layout';

export const filterFields = {
  fromDate: {
    name: 'fromCreatedAt',
    placeholder: 'From',
  },
  toDate: {
    name: 'toCreatedAt',
    placeholder: 'To',
  },
  healthcare: {
    name: 'healthcareSystemId',
    placeholder: 'Healthcare System',
  },
  consultType: {
    name: 'serviceType',
    placeholder: 'Service Type',
  },
  facility: {
    name: 'facilityId',
    placeholder: 'Facility',
  },
};

export const ConsultRequestListFilters = ({ filters, handleFilter, handleApplyFilter, loading }) => {
  const { control, watch, reset } = useForm({
    defaultValues: filters,
  });

  React.useEffect(() => {
    reset(filters);
  }, [reset, filters]);

  const handleHealthcareFilter = React.useCallback(
    (value) => {
      handleFilter({
        [filterFields.healthcare.name]: value,
        [filterFields.facility.name]: null,
      });
    },
    [handleFilter],
  );

  return (
    <FlexBoxAlign flexWrap="wrap">
      <FilterElement>
        <DateTimeFilter
          {...filterFields.fromDate}
          control={control}
          onChange={handleFilter}
        />
      </FilterElement>
      <FilterElement>
        <DateTimeFilter
          {...filterFields.toDate}
          control={control}
          onChange={handleFilter}
        />
      </FilterElement>
      <FilterElement>
        <Autocomplete
          {...filterFields.consultType}
          control={control}
          options={Object.entries(ServiceTypeLabels).map(([id, name]) => ({
            id,
            name,
          }))}
          handleChange={(value) =>
            handleFilter({ [filterFields.consultType.name]: value })
          }
        />
      </FilterElement>
      <FilterElement>
        <HealthSystemSelect
          {...filterFields.healthcare}
          control={control}
          handleChange={handleHealthcareFilter}
        />
      </FilterElement>
      <FilterElement>
        <FacilitySelect
          {...filterFields.facility}
          control={control}
          healthSystem={watch(filterFields.healthcare.name)}
          requiredSystem
          handleChange={(value) =>
            handleFilter({ [filterFields.facility.name]: value })
          }
        />
      </FilterElement>
      <FilterElement>
        <Button
          color="secondary"
          variant="contained"
          disabled={loading}
          onClick={handleApplyFilter}
        >
          Search
        </Button>
      </FilterElement>
    </FlexBoxAlign>
  );
};

ConsultRequestListFilters.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
