import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@mui/material';

import { useFeedback } from 'shared/hooks';
import {
  ServiceTypeEnum,
  ServiceTypeLabels,
  EegConsultLabels,
} from 'utils/enums';

import { patientLocationLabels } from 'features/consults/shared/tabs/Demographics/formFields';
import {
  PatientName,
  RequestDetails,
} from 'features/consults/components/ConsultRequestDetails/ConsultRequestDetails.style';
import NeuroConsultDetails from 'features/consults/components/ConsultRequests/NeuroConsultDetails';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import { Box, FlexBoxColumnCenter } from 'styles/layout';

const EegConsultDetails = ({ eegReadConsultType, statReadRequested }) => (
  <>
    <RequestDetails>{`EEG Type: ${EegConsultLabels[eegReadConsultType]}`}</RequestDetails>
    {statReadRequested && <RequestDetails>STAT Read Requested</RequestDetails>}
  </>
);

EegConsultDetails.propTypes = {
  eegReadConsultType: PropTypes.string,
  statReadRequested: PropTypes.bool,
};

const ConsultRequestDetails = ({
  showDetails,
  onClose,
  onAccept,
  onDecline,
  onDismiss,
  isUpdating,
}) => {
  const { errorDisplay } = useFeedback();

  const handleAccept = React.useCallback(() => {
    onAccept({
      id: showDetails.consultRequest.id,
      cb: onClose,
      errCb: () => errorDisplay('Error accepting consult'),
    });
  }, [showDetails, errorDisplay, onAccept, onClose]);

  const handleDecline = React.useCallback(() => {
    onDecline({
      id: showDetails.consultRequest.id,
      cb: onClose,
      errCb: () => errorDisplay('Error declining consult'),
    });
  }, [showDetails, errorDisplay, onDecline, onClose]);

  const handleDismiss = React.useCallback(() => {
    onDismiss({
      id: showDetails.id,
      cb: onClose,
      errCb: () => errorDisplay('Error dismissing consult'),
    });
  }, [showDetails, errorDisplay, onDismiss, onClose]);

  const renderConsultInfo = React.useMemo(() => {
    if (!showDetails?.consultRequest) return null;
    const { type, values } = showDetails.consultRequest;
    // TODO: missing clinc and teleneuro
    switch (type) {
      case ServiceTypeEnum.NEURO:
        return <NeuroConsultDetails {...values.neuro} />;
      case ServiceTypeEnum.EEG:
        return <EegConsultDetails {...values.eeg} />;
      case ServiceTypeEnum.PEDS_EEG:
        return <EegConsultDetails {...values.eegPediatric} />;
      default:
        return null;
    }
  }, [showDetails?.consultRequest]);

  const renderDetails = React.useMemo(() => {
    if (!showDetails) return null;
    const {
      firstName,
      lastName,
      consultRequest: { age, type, patientLocation, notes },
      facility,
    } = showDetails;

    return (
      <Box mb={2}>
        <PatientName>{`Patient: ${firstName} ${lastName}`}</PatientName>
        {!!age && <RequestDetails>{`Age: ${age}`}</RequestDetails>}
        <RequestDetails>{`Facility: ${facility.name}`}</RequestDetails>
        <RequestDetails>{`Consult Type: ${ServiceTypeLabels[type]}`}</RequestDetails>
        {renderConsultInfo}
        {!!patientLocation && (
          <RequestDetails>{`Patient Location: ${patientLocationLabels[patientLocation]}`}</RequestDetails>
        )}
        {!!notes && <RequestDetails>{`Notes: ${notes}`}</RequestDetails>}
      </Box>
    );
  }, [showDetails, renderConsultInfo]);

  return (
    <Dialog open={!!showDetails} onClose={onClose} fullWidth maxWidth="sm">
      <Box p={10}>
        {renderDetails}
        <FlexBoxColumnCenter mt={5} style={{ gap: 30 }}>
          <ActionButton
            type="button"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleAccept}
            disabled={isUpdating}
          >
            Accept
          </ActionButton>
          <ActionButton
            type="button"
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleDecline}
            disabled={isUpdating}
          >
            Decline
          </ActionButton>
          <ActionButton
            type="button"
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleDismiss}
            disabled={isUpdating}
          >
            Dismiss
          </ActionButton>
        </FlexBoxColumnCenter>
      </Box>
    </Dialog>
  );
};

ConsultRequestDetails.propTypes = {
  showDetails: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    consultRequest: PropTypes.shape({
      id: PropTypes.string,
      age: PropTypes.number,
      type: PropTypes.string,
      patientLocation: PropTypes.string,
      notes: PropTypes.string,
      values: PropTypes.shape({
        neuro: PropTypes.shape({}),
        eeg: PropTypes.shape({}),
        eegPediatric: PropTypes.shape({}),
      }),
    }),
    facility: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

export default ConsultRequestDetails;
