import { neuroConsultOptions } from 'features/consults/utils/constants';
import {
  ServiceTypeEnum,
  ServiceTypeLabels,
  ConsultFieldTypeEnum,
} from 'utils/enums';
import { mapFieldOptions } from 'features/consults/utils/formFields';
import demographicsFields, {
  genderOptions as demographicsGenderOptions,
  teleNeuroDemographicsFields,
  eegDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';

export const teleNeuroLabel = 'Tele Neurohospitalist';

const neuroConsultTypeOptions = neuroConsultOptions.map((consultType) => ({
  ...consultType,
  network: ServiceTypeEnum.NEURO,
}));

export const statOptions = mapFieldOptions(
  demographicsFields.stat.options,
  ConsultFieldTypeEnum.CHIP,
);

export const genderOptions = mapFieldOptions(
  demographicsGenderOptions,
  ConsultFieldTypeEnum.TOGGLE,
);

export const patientLocationOptions = (consultType) =>
  mapFieldOptions(
    [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(consultType)
      ? demographicsFields.eegPatientLocation.options
      : demographicsFields.neuroPatientLocation.options,
    ConsultFieldTypeEnum.TOGGLE,
  );

export const consultTypeOptions = [
  ...mapFieldOptions(neuroConsultTypeOptions, ConsultFieldTypeEnum.TOGGLE),
  {
    value: ServiceTypeEnum.TELE_NEURO,
    content: teleNeuroLabel,
    network: ServiceTypeEnum.TELE_NEURO,
  },
  ...[ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].map((service) => ({
    value: service,
    content: ServiceTypeLabels[service],
    network: service,
  })),
];

export const teleneuroConsultOptions = mapFieldOptions(
  teleNeuroDemographicsFields.consultType.options,
  ConsultFieldTypeEnum.TOGGLE,
);

export const eegConsultOptions = (consultType) =>
  mapFieldOptions(
    consultType === ServiceTypeEnum.PEDS_EEG
      ? eegDemographicsFields.eegPedsConsultType.options
      : eegDemographicsFields.consultType.options,
    ConsultFieldTypeEnum.TOGGLE,
  );

export const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  dateOfBirth: 'dateOfBirth',
  gender: 'gender',
  phoneNumber: 'phoneNumber',
  phoneNumberExtension: 'phoneNumberExtension',
  // TODO: rename to facilityId
  facility: 'facility',
  patientLocation: 'patientLocation',
  consultType: 'type',
  neuroHospitalConsultType: 'neuroHospitalConsultType',
  neuroCameraName: 'neuroCameraName',
  neuroDirectBeamInRequested: 'neuroDirectBeamInRequested',
  eegReadConsultType: 'eegReadConsultType',
  eegTechName: 'eegTechName',
  eegTechCallBackNumber: 'eegTechCallBackNumber',
  eegTechCallBackNumberExtension: 'eegTechCallBackNumberExtension',
  provider: 'providerRequestingConsult',
  videoType: 'videoType',
  statReadRequested: 'statReadRequested',
  notes: 'notes',
  referringRN: 'referringRN',
  rnCallbackNumber: 'rnCallbackNumber',
  rnCallbackNumberExtension: 'rnCallbackNumberExtension',
};
